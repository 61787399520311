<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <CookiesBanner v-if="showCookiesBanner"></CookiesBanner>
    <EnterPrompt
      @enter-clicked="initiateEntrance"
      v-if="!routeWithUniqueEnterPrompt && !isLoggedIn"
      id="enter-prompt"
    ></EnterPrompt>
    <LanguageSelect v-if="!routeWithUniqueLanguageSelect" id="language-select"></LanguageSelect>
    <LanguageModal :class="{ hide: !isLanguageModalOpen }"></LanguageModal>
    <CartModal v-if="routeWithCartModal" v-bind:class="{ hide: !isItemModalOpen }"></CartModal>
    <EntranceModal v-if="!routeWithoutLogin && !routeWithUniqueLoginEntrance"></EntranceModal>
    <transition :transition="$route.meta.transition">
      <router-view></router-view>
    </transition>
    <Footer v-if="hasFooterAndIsNotCart"></Footer>
  </div>
</template>

<script>
import UserApi from "./api/user";
import CookiesBanner from "./components/CookiesBanner";
import EnterPrompt from "./components/EnterPrompt.vue";
import LanguageSelect from "./components/LanguageSelect.vue";
import LanguageModal from "./components/LanguageModal.vue";
import CartModal from "./components/CartModal.vue";
import EntranceModal from "./components/EntranceModal.vue";
import Footer from "./components/Footer";
import { getCurrentGreekDate } from './assets/js/utils.js';

export default {
  name: "App",
  components: {
    CookiesBanner,
    EnterPrompt,
    LanguageSelect,
    LanguageModal,
    CartModal,
    EntranceModal,
    Footer,
  },
  computed: {
    showCookiesBanner() {
      return (
        !localStorage.acceptedCookies &&
        this.$route.name &&
        this.$route.name.includes("landing")
      );
    },

    routeWithUniqueEnterPrompt() {
      if (this.$route.name) {
        return (
          this.$route.name.includes("cart") ||
          this.$route.name.includes("menu") ||
          this.$route.name.includes("open-tables") ||
          this.$route.name.includes("open-rooms") ||
          this.$route.name.includes("services") ||
          this.$route.name.includes("landing") ||
          this.$route.name.includes("pin") ||
          this.$route.name.includes("specific-info") ||
          this.$route.name.includes("order-change-status") ||
          this.$route.name.includes("view-order-details")
        );
      }

      return false;
    },

    routeWithUniqueLanguageSelect() {
      if (this.$route.name) {
        return (
          this.$route.name.includes("cart") ||
          this.$route.name.includes("menu") ||
          this.$route.name.includes("open-tables") ||
          this.$route.name.includes("open-rooms") ||
          this.$route.name.includes("landing") ||
          this.$route.name.includes("services") ||
          this.$route.name.includes("order-change-status") ||
          this.$route.name.includes("view-order-details")
        );
      }

      return false;
    },

    routeWithCartModal() {
      if (this.$route.name) {
        return (
          this.$route.name.includes("cart") || this.$route.name.includes("menu")
        );
      }

      return false;
    },

    routeWithoutLogin() {
      if (this.$route.name) {
        return (
          this.$route.name.includes("profile") ||
          this.$route.name.includes("history") ||
          this.$route.name.includes("landing") ||
          (this.$route.name.includes("review") &&
            !this.$route.name.includes("store"))
        );
      }

      return false;
    },

    routeWithUniqueLoginEntrance() {
      if (this.$route.name) {
        return this.$route.name.includes("cart");
      }

      return false;
    },

    hasFooterAndIsNotCart() {
      if (this.$route.name) {
        return (
          !this.$route.name.includes("info") &&
          !this.$route.name.includes("landing") &&
          !this.$route.name.includes("cart") &&
          !this.$route.name.includes("menu") &&
          !this.$route.name.includes("open-tables") &&
          !this.$route.name.includes("open-rooms") &&
          !this.$route.name.includes("services") &&
          !this.$route.name.includes("profile") &&
          !this.$route.name.includes("pin") &&
          !this.$route.name.includes("order-change-status") &&
          !this.$route.name.includes("view-order-details") &&
          !this.$route.name.includes("history")
        );
      }

      return false;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    isItemModalOpen() {
      return this.$store.state.itemModal.isOpen;
    },
  },
  methods: {
    initiateEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceIn");
    },
  },
  async mounted() {
    let currentGreekDate = await getCurrentGreekDate();
    this.$store.dispatch("setCurrentGreekDate", currentGreekDate)

    if (this.$store.getters.getUserAuthToken === "" && this.$store.getters.getUserJWT === "" && localStorage.userData) {
      let userData = JSON.parse(localStorage.userData);
      this.$store.dispatch("setUserData", userData);

      if (userData.authToken && userData.jwt) {
        UserApi.refreshTokensV2(userData.authToken, userData.jwt);
      }
    } else if (this.$store.getters.getUserAuthToken != "" && this.$store.getters.getUserAuthToken != null && this.$store.getters.getUserJWT != "" && this.$store.getters.getUserJWT != null) {
      UserApi.refreshTokensV2(this.$store.getters.getUserAuthToken, this.$store.getters.getUserJWT);
    }

    setInterval(() => {
      if (this.$store.getters.getUserAuthToken != "" && this.$store.getters.getUserAuthToken != null && this.$store.getters.getUserJWT != "" && this.$store.getters.getUserJWT != null) {
        UserApi.refreshTokensV2(this.$store.getters.getUserAuthToken, this.$store.getters.getUserJWT);
      }
    }, 1200000); // this is 20 minutes
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:300,400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Fluter-font";
  src: url("./assets/fonts/fluter-font-regular.woff2");
}

@font-face {
  font-family: "Fluter-font-bold";
  src: url("./assets/fonts/fluter-font-bold.woff2");
}

#app {
  font-family: "Inter", sans-serif;
  overflow-x: hidden !important;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

p {
  margin-bottom: 1rem;
}

.slide-enter-active {
  animation: coming 1s;
  animation-delay: 0.5s;
  opacity: 0;
}

@keyframes coming {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-leave-active {
  animation: going 1s;
}

@keyframes going {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50px);
    opacity: 0;
  }
}

/* # =================================================================
   # Global selectors
   # ================================================================= */

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; /* Prevent adjustments of font size after orientation changes in iOS */
  word-break: normal;
  -moz-tab-size: 4;
  tab-size: 4;
  overflow-x: hidden;
}

*,
::before,
::after {
  background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements and pseudo elements */
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
  vertical-align: inherit;
}

* {
  padding: 0; /* Reset `padding` and `margin` of all elements */
  margin: 0;
}

/* remove highlight color on mobile tap, when you tap on anything, it gets blue otherwise */
* {
  -webkit-tap-highlight-color: transparent;
}
/* # =================================================================
   # General elements
   # ================================================================= */

hr {
  overflow: visible; /* Show the overflow in Edge and IE */
  height: 0; /* Add the correct box sizing in Firefox */
  color: inherit; /* Correct border color in Firefox. */
}

details,
main {
  display: block; /* Render the `main` element consistently in IE. */
}

summary {
  display: list-item; /* Add the correct display in all browsers */
}

small {
  font-size: 80%; /* Set font-size to 80% in `small` elements */
}

[hidden] {
  display: none; /* Add the correct display in IE */
}

abbr[title] {
  border-bottom: none; /* Remove the bottom border in Chrome 57 */
  /* Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari */
  text-decoration: underline;
  text-decoration: underline dotted;
}

a {
  background-color: transparent; /* Remove the gray background on active links in IE 10 */
}

a:active,
a:hover {
  outline-width: 0; /* Remove the outline when hovering in all browsers */
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; /* Specify the font family of code elements */
}

pre {
  font-size: 1em; /* Correct the odd `em` font sizing in all browsers */
}

b,
strong {
  font-weight: bolder; /* Add the correct font weight in Chrome, Edge, and Safari */
}

/* https://gist.github.com/unruthless/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  border-color: inherit; /* Correct border color in all Chrome, Edge, and Safari. */
  text-indent: 0; /* Remove text indentation in Chrome, Edge, and Safari */
}

/* # =================================================================
   # Forms
   # ================================================================= */

input {
  border-radius: 0;
}

/* Replace pointer cursor in disabled elements */
[disabled] {
  cursor: default;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; /* Correct the cursor style of increment and decrement buttons in Chrome */
}

[type="search"] {
  -webkit-appearance: textfield; /* Correct the odd appearance in Chrome and Safari */
  outline-offset: -2px; /* Correct the outline style in Safari */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; /* Remove the inner padding in Chrome and Safari on macOS */
}

textarea {
  overflow: auto; /* Internet Explorer 11+ */
  resize: vertical; /* Specify textarea resizability */
  -webkit-appearance: none !important;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit; /* Specify font inheritance of form elements */
}

optgroup {
  font-weight: bold; /* Restore the font weight unset by the previous rule */
}

button {
  overflow: visible; /* Address `overflow` set to `hidden` in IE 8/9/10/11 */
}

button,
select {
  text-transform: none; /* Firefox 40+, Internet Explorer 11- */
}

/* Apply cursor pointer to button elements */
button,
[type="button"],
[type="reset"],
[type="submit"],
[role="button"] {
  cursor: pointer;
  color: inherit;
}

/* Remove inner padding and border in Firefox 4+ */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/* Replace focus style removed in the border reset above */
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}

button,
html [type='button'], /* Prevent a WebKit bug where (2) destroys native `audio` and `video`controls in Android 4 */
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; /* Correct the inability to style clickable types in iOS */
}

/* Remove the default button styling in all browsers */
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline-width: 0;
}

/* Style select like a standard input */
select {
  -moz-appearance: none; /* Firefox 36+ */
  -webkit-appearance: none; /* Chrome 41+ */
}

select::-ms-expand {
  display: none; /* Internet Explorer 11+ */
}

select::-ms-value {
  color: currentColor; /* Internet Explorer 11+ */
}

legend {
  border: 0; /* Correct `color` not being inherited in IE 8/9/10/11 */
  color: inherit; /* Correct the color inheritance from `fieldset` elements in IE */
  display: table; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge and IE */
  white-space: normal; /* Correct the text wrapping in Edge and IE */
  max-width: 100%; /* Correct the text wrapping in Edge 18- and IE */
}

::-webkit-file-upload-button {
  /* Correct the inability to style clickable types in iOS and Safari */
  -webkit-appearance: button;
  color: inherit;
  font: inherit; /* Change font properties to `inherit` in Chrome and Safari */
}

/* # =================================================================
   # Specify media element style
   # ================================================================= */

img {
  border-style: none; /* Remove border when inside `a` element in IE 8/9/10 */
}

/* Add the correct vertical alignment in Chrome, Firefox, and Opera */
progress {
  vertical-align: baseline;
}

/* # =================================================================
   # Accessibility
   # ================================================================= */

/* Specify the progress cursor of updating elements */
[aria-busy="true"] {
  cursor: progress;
}

/* Specify the pointer cursor of trigger elements */
[aria-controls] {
  cursor: pointer;
}

/* Specify the unstyled cursor of disabled, not-editable, or otherwise inoperable elements */
[aria-disabled="true"] {
  cursor: default;
}

#enter-prompt {
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 201;
}
@media screen and (max-width: 800px) {
  #enter-prompt {
    position: absolute;
    right: 20px;
    top: 71px;
    margin: 0;
  }
}

@media screen and (max-width: 500px) {
  /* Hide scrollbar for IE, Edge, Firefox*/
  html {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  /* Hide scrollbar for Chrome, Safari*/
  html::-webkit-scrollbar {
    display: none !important;
  }
}

#language-select {
  position: absolute;
  right: 20px;
  top: 80px;
  top: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 800px) {
  #language-select {
    position: absolute;
    left: unset;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

.hide {
  visibility: hidden;
  display: none;
}
</style>
