<template>
  <div id="log-in-sign-up" translate="no">
    <div
      id="entrance-container"
      :class="{
        visibleModal: slideEntranceIn,
        hiddenModal: !slideEntranceIn && entranceAllowedToSlideOut,
        initialHidenModal: !enterButtonPressed,
      }"
      v-if="state == 'ENTRANCE'"
    >
      <div id="enter-label" class="green-brand">
        <font-awesome-icon @click="onCloseClick" id="close-icon" icon="times"></font-awesome-icon>
        <h4>{{ headerText }}</h4>
      </div>
      <div id="entrance-form" v-if="loginMethod=='phone'">
        <div id="phone-label">
          <h4>
            {{ labels.entrance.verifyPhone[language] }}
            <br />
            <span
              style="
                display: inline-block;
                color: #676767;
                font-size: 12px;
                width: 90%;
                margin: 0;
                margin-top: 5px;
                overflow-wrap: anywhere;
              "
            >{{ isSmsSentToUserAtOrderExecution && orderOnHold ? (isServiceWithDelayedResponse ? labels.cart.informViaSmsRegardingRequest[language] :labels.cart.informViaSmsRegardingOrder[language]) : labels.entrance.willSendCode[language] }}</span>
          </h4>
        </div>
        <div id="input-container">
          <vue-tel-input
            id="phone-input"
            defaultCountry="GR"
            @country-changed="countryChanged"
            @enter="enter"
            @input="onPhoneInput"
            placeholder
            :disabled="enterButtonLoading"
            :validCharactersOnly="true"
            :enabledCountryCode="true"
            v-model="phone"
          ></vue-tel-input>
          <div v-if="entranceSuccess == false" class="attemptFailedEntrance">
            <h6 style="max-width: 231px;">{{entranceMessage[language]}}</h6>
          </div>
          <div v-else-if="entranceSuccess == true" class="attemptSucceededEntrance">
            <h6>{{ entranceMessage[language] }}</h6>
          </div>
          <Button
            :loading="enterButtonLoading"
            @click="enter"
            id="sign-up-phone-number-button"
          >{{ submitButtonText }}</Button>
        </div>
      </div>

      <!-- LOGIN WITH PIN -->
      <div id="entrance-form" v-else-if="loginMethod=='pin'">
        <div id="phone-label">
          <h4>Γράψε το pin σου</h4>
        </div>
        <div id="input-container">
          <input
            @input="onPinInput"
            @keypress.enter="loginWithPin"
            type="number"
            id="pin-input"
            v-model="pin"
            placeholder="πχ 1234"
          />
          <div v-if="entranceSuccess == false" class="attemptFailedEntrance">
            <h6 style="max-width: 231px;">{{entranceMessage[language]}}</h6>
          </div>
          <div v-else-if="entranceSuccess == true" class="attemptSucceededEntrance">
            <h6>{{ entranceMessage[language] }}</h6>
          </div>
          <Button
            :loading="enterButtonLoading"
            @click="loginWithPin"
            id="sign-up-phone-number-button"
          >{{ submitButtonText }}</Button>
        </div>
      </div>
      <div id="other-login" v-if="isPda">
        <h6 class="bottom-text">
          <span
            @click="togglePinMethod()"
            class="hyperlink"
          >{{loginMethod=='phone'? 'ΕΙΣΟΔΟΣ ΜΕ PIN' : 'ΕΙΣΟΔΟΣ ΜΕ ΚΙΝΗΤΟ'}}</span>
        </h6>
      </div>
      <div id="terms-label">
        <h6 class="bottom-text">
          {{ labels.entrance.youAgreeTo[language]}}
          <span
            @click="goToInfo('terms-of-service')"
            class="hyperlink"
          >{{labels.info.termsOfService[language]}}</span>
          &
          <span
            @click="goToInfo('privacy-policy')"
            class="hyperlink"
          >{{labels.info.privacyPolicy[language]}}</span>
        </h6>
      </div>
    </div>

    <div
      :class="{
        visibleModal: slideEntranceIn,
        hiddenModal: !slideEntranceIn && entranceAllowedToSlideOut,
        initialHidenModal: !enterButtonPressed,
      }"
      id="verification-container"
      v-else-if="state == 'VERIFICATION'"
    >
      <div id="verification-label" class="green-brand">
        <font-awesome-icon @click="onCloseClick" id="close-icon" icon="times"></font-awesome-icon>
        <h4>{{ headerText }}</h4>
      </div>
      <div id="verification-form">
        <h4 id="sent-code-label">
          {{ labels.entrance.codeSent[language] }}
          <br />
          <span id="verification-phone-number">+{{ dialCode }} {{ userPhone }}</span>
        </h4>
        <div id="verification-code">
          <input
            v-model="code"
            type="number"
            name="verification-code-input"
            id="verification-code-input"
            @keypress.enter="verify"
          />
          <Button
            :disabled="!hasVerificationCode"
            :loading="buttonLoading"
            @click="verify"
            id="verification-submit-button"
          >{{ submitButtonText }}</Button>
        </div>
        <div v-if="verificationSuccess == false" class="attemptFailedVerification">
          <h6>{{ verificationMessage[language] }}</h6>
        </div>
        <div v-else-if="verificationSuccess == true" class="attemptSucceededVerification">
          <h6>{{ verificationMessage[language] }}</h6>
        </div>
        <h5
          @click="changeNumber"
          class="extra-verification-option"
        >{{ labels.entrance.changeNumber[language] }}</h5>
        <h5
          v-if="!resendCodeLoading"
          @click="resendCode"
          class="extra-verification-option"
        >{{ labels.entrance.sendNewCode[language] }}</h5>
        <beat-loader
          v-else
          class="extra-verification-option"
          :loading="resendCodeLoading"
          :color="beatLoaderColor"
          :size="beatLoaderSize"
        ></beat-loader>
        <h5
          v-if="!receiveCodeViaCallLoading"
          @click="receiveNewCodeViaCall"
          class="extra-verification-option"
        >{{ labels.entrance.receiveCodeViaCall[language] }}</h5>
        <beat-loader
          v-else
          class="extra-verification-option"
          :loading="receiveCodeViaCallLoading"
          :color="beatLoaderColor"
          :size="beatLoaderSize"
        ></beat-loader>
      </div>
    </div>
  </div>
</template>

<script>
import UserApi from "../api/user";
import Button from "./Button";
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

export default {
  name: "EntranceModal",
  components: {
    Button,
    BeatLoader,
  },
  props: ["orderOnHold", "isApplication", "isSmsSentToUserAtOrderExecution", "isServiceWithDelayedResponse"],
  watch: {
    isEntranceOpen() {
      this.enterButtonPressed = true;
      this.entranceAllowedToSlideOut = true;
    },
  },

  computed: {
    isEntranceOpen() {
      return this.slideEntranceIn;
    },

    slideEntranceIn() {
      return this.$store.getters.getSlideEntranceIn;
    },

    slideEntranceOut() {
      return this.$store.getters.getSlideEntranceOut;
    },

    phone: {
      get() {
        return this.userPhone;
      },

      set(newPhone) {
        this.userPhone = newPhone;
      },
    },

    buttonLoading() {
      return this.enterButtonLoading || this.verifyButtonLoading;
    },

    entranceSuccess() {
      return this.$store.getters.getEntranceSuccess;
    },

    entranceMessage() {
      return this.$store.getters.getEntranceMessage;
    },

    verificationSuccess() {
      return this.$store.getters.getVerificationSuccess;
    },

    verificationMessage() {
      return this.$store.getters.getVerificationMessage;
    },

    hasVerificationCode() {
      return this.code != undefined && this.code.trim() != "";
    },

    submitButtonText() {
      return this.orderOnHold
        ? this.labels.entrance.continue[this.language]
        : this.labels.entrance.enter[this.language];
    },

    headerText() {
      return this.orderOnHold
        ? this.labels.entrance.completeOrder[this.language]
        : this.labels.entrance.enterFluter[this.language];
    },

    storeId() {
      return this.$store.getters.getStoreId;
    },

    isPda() {
      return this.$store.getters.getTablePda;
    },

    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      userPhone: "",
      dialCode: "",
      entranceAllowedToSlideOut: false,
      enterButtonLoading: false,
      verifyButtonLoading: false,
      resendCodeLoading: false,
      receiveCodeViaCallLoading: false,
      code: undefined,
      beatLoaderSize: "8px",
      beatLoaderColor: "black",
      enterButtonPressed: false,
      loginMethod: "phone",
      pin: null,
      // available states: ENTRANCE, VERIFICATION
      state: "ENTRANCE",
    };
  },

  methods: {
    onSuccessfullVerification: function () {
      let timeOut = this.loginMethod == "phone" ? 500 : 200;
      setTimeout(() => {
        this.onCloseClick();
        this.$emit("enter");
      }, timeOut);
    },

    onCloseClick: function () {
      this.enterButtonLoading = false;
      this.verifyButtonLoading = false;
      this.pin = null;

      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");

        this.state = "ENTRANCE";
        this.userPhone = "";
      }, 850);
    },

    changeNumber: function () {
      this.$store.dispatch("resetEntranceState");
      this.$store.dispatch("setEntranceMessage", "");
      this.state = "ENTRANCE";
    },

    resendCode: async function () {
      this.resendCodeLoading = true;
      let phoneNumberInParts = this.userPhone.split(" ");
      this.$store.dispatch("setVerificationMessage", "");
      if (phoneNumberInParts[0] == `+${this.dialCode}`) {
        phoneNumberInParts.shift();
        this.userPhone = phoneNumberInParts.join(" ");
      }

      let phone = {
        phoneNumber: Number(this.userPhone.replace(/\D/g, "")),
        countryCode: Number(this.dialCode),
      };

      let response = await UserApi.sendEntanceSMS(phone, "sms");

      this.$store.dispatch("setVerificationMessage", response.message);

      if (response.status == 200)
        this.$store.dispatch("successfullVerification");
      else this.$store.dispatch("failedVerification");

      this.resendCodeLoading = false;
    },

    receiveNewCodeViaCall: async function () {
      this.receiveCodeViaCallLoading = true;
      let phoneNumberInParts = this.userPhone.split(" ");
      this.$store.dispatch("setVerificationMessage", "");
      if (phoneNumberInParts[0] == `+${this.dialCode}`) {
        phoneNumberInParts.shift();
        this.userPhone = phoneNumberInParts.join(" ");
      }

      let phone = {
        phoneNumber: Number(this.userPhone.replace(/\D/g, "")),
        countryCode: Number(this.dialCode),
      };

      let response = await UserApi.sendEntanceSMS(phone, "call");

      this.$store.dispatch("setVerificationMessage", response.message);

      if (response.status == 200)
        this.$store.dispatch("successfullVerification");
      else this.$store.dispatch("failedVerification");

      this.receiveCodeViaCallLoading = false;
    },

    onPhoneInput() {
      if (this.entranceSuccess == false) {
        this.$store.dispatch("setEntranceMessage", "");
      }
    },

    onPinInput() {
      this.$store.dispatch("setEntranceMessage", "");
    },

    enter: async function () {
      this.$store.dispatch("setEntranceMessage", "");
      if (this.userPhone == null || this.userPhone == undefined || this.userPhone.trim().length == 0) {
        let errorMessage = {
          GRE: "Εισάγετε το κινητό σας",
          ENG: "Please type your phone number"
        }
        this.$store.dispatch("setEntranceMessage", errorMessage);
        this.$store.dispatch("failedEntrance");
        return;
      }

      this.enterButtonLoading = true;
      let phoneNumberInParts = this.userPhone.split(" ");
      if (phoneNumberInParts[0] == `+${this.dialCode}`) {
        phoneNumberInParts.shift();
        this.userPhone = phoneNumberInParts.join(" ");
      }

      let phone = {
        phoneNumber: Number(this.userPhone.replace(/\D/g, "")),
        countryCode: Number(this.dialCode),
      };

      let response = await UserApi.enter(phone, this.storeId);

      this.$store.dispatch("setEntranceMessage", response.message);

      if (response.status == 200) {
        this.$store.dispatch("successfullEntrance");
        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
        this.state = "VERIFICATION";
      } else {
        this.$store.dispatch("failedEntrance");
      }

      this.enterButtonLoading = false;
    },

    async loginWithPin() {
      this.$store.dispatch("setEntranceMessage", "");
      if (this.pin == null || this.pin == undefined || this.pin.trim().length == 0) {
        let errorMessage = {
          GRE: "Εισάγετε το pin σας",
          ENG: "Please type your pin"
        }
        this.$store.dispatch("setEntranceMessage", errorMessage);
        this.$store.dispatch("failedEntrance");
        return;
      }

      this.enterButtonLoading = true;
      let response = await UserApi.verifyWithPin(this.pin, this.storeId);
      if (response.status == 200) {
        this.$store.dispatch("successfullVerification");
        this.$store.dispatch("setUserData", response.user);
        this.onSuccessfullVerification();
      } else {
        this.$store.dispatch("setEntranceMessage", response.message);
        this.$store.dispatch("failedEntrance");
      }

      this.enterButtonLoading = false;
    },

    verify: async function () {
      this.$store.dispatch("setVerificationMessage", "");
      this.verifyButtonLoading = true;
      let phoneNumberInParts = this.userPhone.split(" ");
      if (phoneNumberInParts[0] == `+${this.dialCode}`) {
        phoneNumberInParts.shift();
        this.userPhone = phoneNumberInParts.join(" ");
      }

      let phone = {
        phoneNumber: Number(this.userPhone.replace(/\D/g, "")),
        countryCode: Number(this.dialCode),
        password: String(this.code),
      };

      let response = await UserApi.verifyV2(phone);

      this.$store.dispatch("setVerificationMessage", response.message);

      if (response.status == 200) {
        this.$store.dispatch("successfullVerification");
        this.$store.dispatch("setUserData", response.user);
        this.onSuccessfullVerification();
      } else {
        this.$store.dispatch("failedVerification");
      }

      this.verifyButtonLoading = false;
    },

    countryChanged: function (newCountry) {
      this.dialCode = newCountry.dialCode;
    },

    togglePinMethod() {
      if (this.loginMethod == 'pin') {
        this.loginMethod = 'phone'
      } else if (this.loginMethod == 'phone') {
        this.loginMethod = 'pin';
        setTimeout(() => {
          try {
            document.getElementById("pin-input").focus();
          } catch (error) {
            console.log("Could not focus automatically to pin-input");
          }
        }, 250);

      } else {
        this.loginMethod = 'phone'
      }
      this.$store.dispatch("setEntranceMessage", "");
    },

    goToInfo: function (infoType) {
      this.onCloseClick();

      let queryObject = {};
      if (this.isApplication) {
        queryObject.isApplication = true;
      }
      this.$router.push({ name: "specific-info", params: { type: infoType }, query: queryObject });
    },
  },
};
</script>

<style scoped>
.hide {
  display: none;
  visibility: hidden;
}

#input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.green-brand {
  background: linear-gradient(62deg, #198b4a, #10bc66);
}

.dark-brand {
  background: #003340;
}

#entrance-container,
#verification-container {
  position: fixed;
  top: 45%;
  left: 50%;
  height: 60vh;
  min-height: 350px;
  max-height: 680px;
  width: 400px;
  background: #f7f7f7;
  color: gray;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 207;
  transform: translate(-50%, -50%);
}

#verification-container {
  height: 75vh;
}

@media screen and (min-height: 1080px) {
  #entrance-container,
  #verification-container {
    height: 50vh;
  }
}
@media screen and (max-width: 1285px) {
  #entrance-container,
  #verification-container {
    width: 85vw !important;
    max-width: 380px;
    height: 107vw;
    max-height: 500px;
    min-height: 240px;
  }

  #verification-container {
    height: 125vw;
    min-height: 400px;
  }
}

@media screen and (max-width: 260px) {
  #entrance-container,
  #verification-container {
    height: 80vh;
  }
}

@media screen and (max-width: 1285px) and (max-height: 580px) {
  #entrance-container,
  #verification-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw !important;
    height: 100%;
    min-height: unset;
    max-height: unset;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 0;
    top: 50% !important;
    left: 50%;
    transition: all 0.3s ease-in-out;
  }
}

#enter-label,
#verification-label {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-weight: 700;
  width: 100%;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.16), 0 2px 1px rgba(0, 0, 0, 0.23);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
@media screen and (max-width: 1285px) {
  #verification-label {
    flex: 0.7;
  }
}
@media screen and (max-width: 1285px) and (max-height: 580px) {
  #enter-label,
  #verification-label {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex: none;
    height: 16.5%;
    min-height: 100px;
    max-height: unset;
    overflow: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

#enter-label > h4,
#verification-label > h4 {
  font-size: 18px;
  margin-top: 1vh;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1285px) {
  #enter-label > h4,
  #verification-label > h4 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1285px) and (max-height: 580px) {
  #enter-label > h4,
  #verification-label > h4 {
    margin: 0 !important;
    width: 100%;
  }
}

#close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #eff;
  font-size: 17px;
}

#verification-phone-number {
  font-weight: 700;
  color: #198b4a;
}

#entrance-form,
#verification-form {
  flex: 1.5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  color: gray;
}
@media screen and (max-width: 330px) {
  #entrance-form {
    flex: 1.3 !important;
  }
}

#verification-form {
  flex: 2.6;
  margin-top: 0;
}

@media screen and (max-width: 1285px) {
  #verification-form {
    flex: 2.3 !important;
  }
}
@media screen and (max-width: 1285px) and (max-height: 580px) {
  #verification-form {
    flex: unset !important;
    height: 100%;
    min-height: 350px;
  }

  #entrance-form {
    flex: unset !important;
    height: 100%;
    min-height: 250px;
  }
}

.extra-verification-option {
  color: #333;
  border-bottom: 1px solid #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-left: 10%;
  cursor: pointer;
}
@media screen and (max-width: 1285px) {
  .extra-verification-option {
    font-size: 13px;
  }
}
@media screen and (max-width: 330px) {
  .extra-verification-option {
    font-size: 12px;
  }
}
@media screen and (max-width: 300px) {
  .extra-verification-option {
    font-size: 11px;
  }
}

#phone-label > h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

#phone-label {
  margin-left: 7.5%;
}

input,
#phone-input {
  margin-left: 8%;
  background: #f7f7f7;
  width: 90%;
  height: 4vh;
  min-height: 25px;
  outline: none;
  border: none;
  border-bottom: 1.5px solid #198b4a;
  -webkit-appearance: none !important;
}

#sign-up-phone-number-button {
  height: 5vh;
  width: 90%;
  min-height: 40px;
  max-height: 55px;
  margin-top: 16px;
  margin-left: 8%;
  border-radius: 0 16px 16px 0;
  box-shadow: unset;
  border: none;
  background: #198b4a;
  color: white;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

@media screen and (max-width: 1285px) {
  input,
  #phone-input {
    font-size: 14px;
    outline: none;
    width: 66vw;
    max-width: 310px;
    margin-left: 10%;
  }

  #sign-up-phone-number-button {
    margin-left: 10%;
    width: 100%;
    max-width: 310px;
  }
}

@media screen and (max-width: 270px) {
  #sign-up-phone-number-button {
    width: 90%;
  }
}

#verification-code {
  display: flex;
  width: 85%;
  align-self: center;
}

#verification-code-input {
  width: 62%;
  margin-left: 2.5%;
  border: none;
  -webkit-box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  text-align: right;
  padding-right: 10px;
  padding-left: 5px;
  min-height: 30px;
  font-size: 17px;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 0vh;
  -webkit-appearance: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.failed {
  border-bottom: 1.5px solid #f30;
}

#country-code {
  width: 10%;
  margin-right: 5%;
  margin-left: 1%;
}

#plus {
  margin: 0;
  padding: 0;
}

#verification-submit-button {
  height: 4vh;
  min-height: 30px;
  border-radius: 0 16px 16px 0;
  box-shadow: unset;
  border: none;
  background: #198b4a;
  color: white;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

#verification-submit-button:disabled {
  background: gray;
}

#other-login {
  width: 100%;
  /* white-space: pre-wrap; */
  margin-left: 16%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0.3;
  margin-bottom: 10px !important;
}

#terms-label {
  width: 100%;
  /* white-space: pre-wrap; */
  margin-left: 16%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0.3;
  margin-bottom: 2vh;
}

.bottom-text {
  width: 80%;
  font-weight: 400;
  font-size: 13px;
}
@media screen and (max-width: 300px) {
  .bottom-text {
    font-size: 11px;
  }
}

#sent-code-label {
  text-align: center;
  margin: 0;
  padding: 0 7.5% 0 7.5%;
  color: #333;
  font-size: 16px;
}
@media screen and (max-width: 1285px) {
  #sent-code-label {
    font-size: 14px;
  }
}
@media screen and (max-width: 330px) {
  #sent-code-label {
    font-size: 13px;
  }
}
@media screen and (max-width: 300px) {
  #sent-code-label {
    font-size: 12px;
  }
}

.attemptFailedEntrance,
.attemptSucceededEntrance {
  width: 100%;
  margin-top: 8px;
  margin-left: 8%;
}

@media screen and (max-width: 1285px) {
  .attemptFailedEntrance,
  .attemptSucceededEntrance {
    margin-left: 10%;
  }
}

.attemptFailedEntrance > h6 {
  font-size: 14px;
  font-weight: 600;
  color: #f30;
  white-space: pre-wrap;
  margin-bottom: 0;
}

.attemptFailedVerification,
.attemptSucceededVerification {
  width: 100%;
  margin-left: 10%;
}

.attemptFailedVerification > h6 {
  font-size: 14px;
  color: #f30;
  white-space: pre-wrap;
  margin-bottom: 0;
}

.attemptSucceededEntrance > h6 {
  font-size: 14px;
  font-weight: 600;
  color: #198b4a;
  width: 50%;
  margin-bottom: 0;
}

.attemptSucceededVerification > h6 {
  font-size: 14px;
  color: #198b4a;
  width: 50%;
  margin-bottom: 0;
}

.hide {
  display: none;
}

.hyperlink {
  cursor: pointer;
  color: #6277ff;
  border-bottom: 1px solid #6277ff;
}

.slideOut {
  animation: slideOut 0.85s forwards ease-in-out,
    resetPosition 0s forwards 0.85s;
}

.visibleModal {
  visibility: visible;
}

.hiddenModal {
  visibility: hidden;
}

.initialHidenModal {
  visibility: hidden;
}

.slideIn {
  animation: slideIn 0.85s forwards ease-in-out 0.3s;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: unset;
  }

  to {
    opacity: 0;
    transform: translate(-50%, 30%);
    visibility: hidden;
  }
}

@keyframes resetPosition {
  from {
    transform: translate(-50%, 30%);
  }

  to {
    transform: translate(-50%, -130%);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(-50%, -130%);
    visibility: hidden;
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
    visibility: unset;
  }
}

@media screen and (max-width: 1285px) {
  #phone-label > h4 {
    font-size: 14px;
  }

  .attemptFailedEntrance > h6 {
    font-size: 13px;
  }

  .attemptFailedVerification > h6 {
    font-size: 13px;
  }

  #sign-up-phone-number-button,
  #verification-submit-button {
    font-size: 14px;
  }
}
</style>