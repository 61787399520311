import { render, staticRenderFns } from "./LanguageSelect.vue?vue&type=template&id=1bb80936&scoped=true&style=cursor%3A%20pointer%3B&"
import script from "./LanguageSelect.vue?vue&type=script&lang=js&"
export * from "./LanguageSelect.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSelect.vue?vue&type=style&index=0&id=1bb80936&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bb80936",
  null
  
)

export default component.exports