<template>
  <div id="language-modal">
    <div id="images">
      <img class="flag-image" @click="setLanguage('GRE')" src="../assets/greek_flag.png" alt />
      <img class="flag-image" @click="setLanguage('ENG')" src="../assets/uk_flag.png" alt />
      <img
        class="flag-image"
        v-if="hasBulgarian"
        @click="setExtraLanguage('BGR')"
        src="../assets/bgr_flag.png"
        alt
      />
      <img
        class="flag-image"
        v-if="hasItalian"
        @click="setExtraLanguage('ITA')"
        src="../assets/ita_flag.png"
        alt
      />
      <img
        class="flag-image"
        v-if="hasFrench"
        @click="setExtraLanguage('FRA')"
        src="../assets/fra_flag.png"
        alt
      />
      <img
        class="flag-image"
        v-if="hasGerman"
        @click="setExtraLanguage('DEU')"
        src="../assets/deu_flag.png"
        alt
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageModal",
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
    hasBulgarian() {
      return this.$store.getters.getExtraLanguages.includes('BGR');
    },
    hasItalian() {
      return this.$store.getters.getExtraLanguages.includes('ITA');
    },
    hasFrench() {
      return this.$store.getters.getExtraLanguages.includes('FRA');
    },
    hasGerman() {
      return this.$store.getters.getExtraLanguages.includes('DEU');
    }
  },
  methods: {
    setLanguage: function (language) {
      this.$store.dispatch("setLanguage", language);
      this.$store.dispatch("closeLanguageModal");
    },
    setExtraLanguage: function (language) {
      this.$store.dispatch("setSelectedExtraLanguage", language);
      this.$store.dispatch("closeLanguageModal");
    },
  },
};
</script>

<style scoped>
#language-modal {
  position: fixed;
  top: 25%;
  left: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  height: 65%;
  background: #f7f7f7;
  border-radius: 10px;
  z-index: 205;
}

#title {
  width: 90%;
  justify-self: center;
}

#title > h4 {
  text-align: center;
  font-size: 26px;
}

#images {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flag-image {
  width: 150px;
  height: auto;
  cursor: pointer;
  border: 2px solid #003340;
}

@media (max-width: 800px) {
  #language-modal {
    width: 60vw;
    left: 20vw;
    top: 15vh;
  }

  #title > h4 {
    font-size: 21px;
  }

  .flag-image {
    width: 50%;
    max-width: 220px;
    max-height: 200px;
  }
}

@media (max-width: 800px) and (max-height: 600px) {
  .flag-image {
    width: 35%;
    max-height: 200px;
  }
}

@media (min-width: 800px) and (max-height: 770px) {
  .flag-image {
    width: 35%;
    max-height: 200px;
  }
}
</style>