<template>
  <div
    class="subselection-category-container"
    @click="revealOptions($event)"
    :id="'sub-'+subselection.id"
    :class="{paddingBottom: hideOptions && selectedSubOptionsText=='' && $screen.width <= 885, mobileCategoryContainer: $screen.width <= 885}"
  >
    <div>
      <h4 id="subselection-category-title" :class="{marginBottom: $screen.width > 885}">
        {{ subselection.names.translation[language].name }}
        <span
          style="color: #198b4a;"
          v-if="subselection.mandatory"
        >*</span>
        <font-awesome-icon
          v-if="hideOptions && $screen.width <= 885"
          icon="chevron-down"
          class="expand-options-icon"
        ></font-awesome-icon>
        <font-awesome-icon
          v-else-if="!hideOptions && $screen.width <= 885"
          icon="chevron-up"
          class="expand-options-icon"
        ></font-awesome-icon>
      </h4>
      <h5
        v-if="subselection.type == 'CHECKBOX' && subselection.maximumSelected > 0"
        id="subselection-category-maxselected"
      >*{{labels.cartModal.upTo[language]}} {{subselection.maximumSelected}} {{labels.cartModal.options[language]}}</h5>
    </div>

    <div class="suboptions-text-full" v-if="hideOptions && selectedSubOptionsText!=''">
      <font-awesome-icon icon="check" style="color: #198b4a"></font-awesome-icon>
      {{selectedSubOptionsText}}
    </div>

    <div
      class="suboptions-text-empty"
      v-if="hideOptions && selectedSubOptionsText==''"
    >{{ labels.cartModal.optionalChoice[language] }}</div>

    <div id="subselection-category-items" v-if="!hideOptions">
      <SubselectionItem
        v-for="(item, index) in subselection.options"
        :key="item.id"
        :item="item"
        :categoryId="subselection.id"
        :maximumSelected="subselection.maximumSelected"
        :mandatory="subselection.mandatory"
        :type="subselection.type"
        :priceAggregated="subselection.priceAggregated"
        :productPrice="productPrice"
        :priceable="priceable"
        :showBorderBottom="index!=subselection.options.length-1 && $screen.width <= 885"
      ></SubselectionItem>
    </div>
  </div>
</template>

<script>
import SubselectionItem from "./SubselectionItem.vue";
export default {
  name: "SubselectionCategory",
  components: {
    SubselectionItem,
  },
  props: ["subselection", "productPrice", "priceable", "isEdit", "amountOfSubselections"],
  data() {
    return {
      hideOptions: true
    };
  },
  watch: {
    $screen(newVal, oldVal) {
      console.log(newVal.width);
    },
  },
  methods: {
    revealOptions(event) {
      if (this.$screen.width <= 885) {
        if (event.target.id == 'subselection-category-title' || event.target.tagName == 'path' || event.target.tagName == 'svg') {
          this.hideOptions = !this.hideOptions;
          if (!this.hideOptions) {
            this.$emit("new-subcategory-selected", this.subselection.id);
          }
        } else if (this.hideOptions) {
          this.hideOptions = false;
          this.$emit("new-subcategory-selected", this.subselection.id);
        }
      }
    },
    onNewSubCategorySelected(categoryId) {
      if (this.subselection.id != categoryId && this.subselection.options.length > 1) {
        this.hideOptions = true;
      }
      if (this.subselection.id == categoryId) {
        setTimeout(() => {
          let subSelectionCategoryElement = document.getElementById("sub-" + this.subselection.id);

          let itemAndDescriptionElement = document.getElementById("item-name-n-description");
          let itemsAndDescriptionHeight = itemAndDescriptionElement.offsetHeight;

          let cartModal = document.getElementById("cart-modal");
          cartModal.scrollTo({
            behavior: 'smooth',
            top: subSelectionCategoryElement.offsetTop - itemsAndDescriptionHeight - 12
          })
        }, 35);
      }
    }
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
    selectedItem() {
      return this.$store.getters.getSelectedItem;
    },
    selectedSubOptionsText() {
      let subOptionsText = "";
      let subselectionCategoryInSelectedItem = this.selectedItem.subselections.find(categoryInSelectedItem => categoryInSelectedItem.id == this.subselection.id);
      subselectionCategoryInSelectedItem.options.forEach(option => {
        if (option.isSelected) {
          subOptionsText = subOptionsText + option.names.translation[this.language].name + ", "
        }
      });
      if (subOptionsText != "" && subOptionsText.endsWith(", ")) {
        subOptionsText = subOptionsText.slice(0, -2);
      }
      return subOptionsText;
    }
  },
  mounted() {
    if (this.$screen.width > 885) {
      this.hideOptions = false;
    } else if (this.isEdit) {
      this.hideOptions = true;
    } else if (this.amountOfSubselections == 1) {
      this.hideOptions = false;
    } else if ((this.subselection.mandatory && this.subselection.type == 'RADIO_BUTTON') || this.subselection.options.length == 1) {
      this.hideOptions = false;
    } else {
      this.hideOptions = true;
    }
  },
};
</script>

<style scoped>
.subselection-category-container {
  margin-bottom: 20px;
}

.mobileCategoryContainer {
  background: #fff;
  border-radius: 6px;
  padding: 0px 8px 0px 8px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.paddingBottom {
  padding: 0px 8px 7px 8px;
}

.marginBottom {
  margin-bottom: 8px !important;
}

.expand-options-icon {
  color: #707070;
  float: right;
  padding-right: 4px;
  padding-top: 2px;
}

#subselection-category-items {
  display: grid;
  grid-template-columns: repeat(2, 51.5%);
  grid-gap: 10px 0px;
  margin-bottom: 6px;
}

.suboptions-text-full {
  padding-bottom: 6px;
  padding-right: 6px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(105, 105, 105);
}

.suboptions-text-empty {
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  color: rgb(64, 64, 64);
  background-color: #e5e5e5;
  padding: 3px 4px 3px 4px;
  border-radius: 4px;
}

@media screen and (max-width: 885px) {
  #subselection-category-items {
    grid-gap: 0px 0px !important;
    grid-template-columns: repeat(1, 105%);
  }
}

#subselection-category-title {
  font-weight: 600;
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #003340;
  margin-bottom: 0px;
}

#subselection-category-maxselected {
  font-size: 13px;
  margin-bottom: 2px;
  color: rgb(37, 37, 37);
}

@media screen and (max-width: 753px) {
  #subselection-category-title {
    font-size: 15px;
  }
}
</style>