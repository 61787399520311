import axios from "axios";

export default {

    stompClientConnected(orderId) {
        axios.post(`log/stomp-client-connected?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    stompClientDisconnected(orderId) {
        axios.post(`log/stomp-client-disconnected?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    messageReceivedInSubscription(orderId) {
        axios.post(`log/message-received-in-subscription?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    unhandledMessageReceived(orderId) {
        axios.post(`log/unhandled-message-received?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    errorAtMessageReceiptInSubscription(orderId) {
        axios.post(`log/error-at-message-receipt-in-subscription?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    orderStatusReturnedInSubscription(orderId, status) {
        axios.post(`log/order-status-returned-in-subscription?orderId=${orderId}&status=${status}`)
            .catch((error) => console.log(error));
    },

    orderStatusReturnedFromApiCall(orderId, status) {
        axios.post(`log/order-status-returned-from-api-call?orderId=${orderId}&status=${status}`)
            .catch((error) => console.log(error));
    },

    orderStatusReturnedFromBackupCall(orderId, status) {
        axios.post(`log/order-status-returned-from-backup-call?orderId=${orderId}&status=${status}`)
            .catch((error) => console.log(error));
    },

    orderStatusReturnedFromStompErrorPolling(orderId, status) {
        axios.post(`log/order-status-returned-from-stomp-error-polling?orderId=${orderId}&status=${status}`)
            .catch((error) => console.log(error));
    },

    orderStatusReturnedFromWebsocketErrorPolling(orderId, status) {
        axios.post(`log/order-status-returned-from-websocket-error-polling?orderId=${orderId}&status=${status}`)
            .catch((error) => console.log(error));
    },

    stompError(orderId) {
        axios.post(`log/stomp-error?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    websocketError(orderId) {
        axios.post(`log/websocket-error?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    enablePollingAtStompError(orderId) {
        axios.post(`log/polling-enabled-at-stomp-error?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    enablePollingAtWebsocketError(orderId) {
        axios.post(`log/polling-enabled-at-websocket-error?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    enableBackupPolling(orderId) {
        axios.post(`log/backup-polling-enabled?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    closedCartScreenWhilePending(orderId) {
        axios.post(`log/cart-screen-closed-while-pending?orderId=${orderId}`)
            .catch((error) => console.log(error));
    },

    sendOrderClicked(storeId) {
        axios.post(`log/send-order-clicked?storeId=${storeId}`)
            .catch((error) => console.log(error));
    },

    sendOrderClickedWithoutRoom(storeId) {
        axios.post(`log/send-order-clicked-without-room?storeId=${storeId}`)
            .catch((error) => console.log(error));
    },

    enterFluterClicked() {
        axios.post(`log/enter-fluter-clicked`)
            .catch((error) => console.log(error));
    },

    closeTableScreenInitialized(tableOpeningId, authToken) {
        axios.post(`log/close-table-screen-initialized?tableOpeningId=${tableOpeningId}&authToken=${authToken}`)
            .catch((error) => console.log(error));
    },

    closeTableButtonPressed(tableOpeningId, paymentType, authToken) {
        axios.post(`log/close-table-button-pressed?tableOpeningId=${tableOpeningId}&paymentType=${paymentType}&authToken=${authToken}`)
            .catch((error) => console.log(error));
    },

    closeTableConfirmed(tableOpeningId, authToken) {
        axios.post(`log/close-table-confirmed?tableOpeningId=${tableOpeningId}&authToken=${authToken}`)
            .catch((error) => console.log(error));
    },

    closeTableCancelled(tableOpeningId, authToken) {
        axios.post(`log/close-table-cancelled?tableOpeningId=${tableOpeningId}&authToken=${authToken}`)
            .catch((error) => console.log(error));
    },

    tableClosedSuccesfully(tableOpeningId, authToken) {
        axios.post(`log/table-closed-successfully?tableOpeningId=${tableOpeningId}&authToken=${authToken}`)
            .catch((error) => console.log(error));
    },

    closeTableNotCorrectlyInputsFilled(tableOpeningId, reason, authToken) {
        axios.post(`log/close-table-not-correctly-inputs-filled?tableOpeningId=${tableOpeningId}&reason=${reason}&authToken=${authToken}`)
            .catch((error) => console.log(error));
    },

    closeTableError(tableOpeningId, authToken) {
        axios.post(`log/error-at-close-table?tableOpeningId=${tableOpeningId}&authToken=${authToken}`)
            .catch((error) => console.log(error));
    },
};
