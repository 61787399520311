import { store } from "./store/store";

export const routes = [
  {
    name: "reviews",
    path: "/review/:orderId",
    component: () => import("./components/screens/LeaveReview"),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next({ name: "landing" })
      } else {
        next()
      }
    },
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "profile",
    path: "/profile/:userId",
    component: () => import("./components/screens/ProfileScreen"),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next({ name: "landing" })
      } else {
        next()
      }
    },
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "profile-with-delete",
    path: "/profile/:userId/delete",
    component: () => import("./components/screens/ProfileScreen"),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next({ name: "landing" })
      } else {
        next()
      }
    },
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "history",
    path: "/history/:userId",
    component: () => import("./components/screens/History"),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next({ name: "landing" })
      } else {
        next()
      }
    },
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "history-with-entrance",
    path: "/history/:userId/entrance",
    component: () => import("./components/screens/History"),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next({ name: "landing" })
      } else {
        next()
      }
    },
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    path: '/',
    name: 'landing',
    component: () => import("./components/screens/Landing"),
    meta: { sitemap: { priority: 1.0 } },
  },
  {
    name: "store-reviews",
    path: "/stores/:storeId/reviews",
    component: () => import("./components/screens/StoreReviews"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "info",
    path: "/info",
    component: () => import("./components/screens/Info"),
    meta: { sitemap: { priority: 0.5 } },
  },
  {
    name: "specific-info",
    path: "/info/:type",
    component: () => import("./components/screens/Info"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "order-change-status",
    path: "/order-change-status",
    component: () => import("./components/screens/ChangeOrderStatus"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "view-order-details",
    path: "/view-order-details/:orderId/:restaurantId/:token",
    component: () => import("./components/screens/SeeOrderDetails"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "pin",
    path: "/pin",
    component: () => import("./components/screens/Pin"),
    meta: { sitemap: { priority: 0.5 } },
  },
  {
    name: "services",
    path: "/menu/:tableId",
    component: () => import("./components/screens/Services"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "open-tables",
    path: "/open-tables/for-store/:storeId/:tableId",
    component: () => import("./components/screens/TablesScreen"),
    props: true,
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "open-rooms",
    path: "/open-rooms/for-store/:storeId/:tableId",
    component: () => import("./components/screens/TablesScreen"),
    props: true,
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "menu",
    path: "/:service/:tableId",
    component: () => import("./components/screens/MenuScreen"),
    props: true,
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "menu-with-categories",
    path: "/:service/:tableId/categories",
    component: () => import("./components/screens/MenuScreen"),
    props: true,
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "menu-with-happy-hour",
    path: "/:service/:tableId/happy-hour",
    component: () => import("./components/screens/MenuScreen"),
    props: true,
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "cart",
    path: "/:service/:tableId/cart",
    component: () => import("./components/screens/CartScreen"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "cart-with-change-phone",
    path: "/:service/:tableId/cart/change-phone",
    component: () => import("./components/screens/CartScreen"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  {
    name: "cart-with-delete-card",
    path: "/:service/:tableId/cart/delete-card",
    component: () => import("./components/screens/CartScreen"),
    meta: { sitemap: { ignoreRoute: true } }
  },
  // {
  //   name: "catalogue",
  //   path: "/:catalogue/:tableId",
  //   component: () => import("./components/screens/Catalogue")
  // },
];
