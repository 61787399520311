
import axios from "axios";

function getResponseMessage(responseMessage) {
    let message = {
        GRE: "",
        ENG: ""
    };

    if (responseMessage.startsWith('CUSTOM_MESSAGE: ')) {
        message.GRE = responseMessage.replace('CUSTOM_MESSAGE: ', '');
        message.ENG = responseMessage.replace('CUSTOM_MESSAGE: ', '');
        return message;
    }

    switch (responseMessage) {
        case 'EMPTY_PHONE':
            message.GRE = 'O αριθμός τηλεφώνου ή ο κωδικός χώρας δεν μπορούν να είναι κενά';
            message.ENG = 'Phone number or country code cannot be empty';
            break;
        case 'INVALID_PHONE':
            message.GRE = 'Mη έγκυρος αριθμός τηλεφώνου. Βεβαιωθείτε ότι ο κωδικός χώρας του τηλ σας είναι ο σωστός. Μπορείτε να αλλάξετε τον κωδικό χώρας, κάνοντας κλικ στο εικονίδιο με τη σημαία που βρίσκεται δίπλα στον αριθμό τηλεφώνου';
            message.ENG = 'Invalid phone number. Please ensure the country code of your phone number is the correct one. You can change the country code by clicking on the flag icon next to the phone input';
            break;
        case 'INVALID_PASSWORD':
            message.GRE = 'Ο κωδικός πρέπει να περιέχει τουλάχιστον 6 χαρακτήρες';
            message.ENG = 'The password must contain at least 6 characters';
            break;
        case 'EMPTY_EMAIL':
            message.GRE = 'Tο email δεν μπορεί να είναι κενό';
            message.ENG = 'Email address cannot be empty';
            break;
        case 'INVALID_EMAIL':
            message.GRE = 'Μη έγκυρη διέυθυνση email';
            message.ENG = 'Invalid email address';
            break;
        case 'INVALID_EMAIL_TO_NOTIFY_WHEN_ORDER_EXECUTED_MISSING':
            message.GRE = 'Μη έγκυρη διέυθυνση email';
            message.ENG = 'Invalid email address';
            break;
        case 'EMAIL_TO_NOTIFY_WHEN_ORDER_EXECUTED_MISSING':
            message.GRE = 'Μη έγκυρη διέυθυνση email';
            message.ENG = 'Invalid email address';
            break;
        case 'EMAIL_ALREADY_USED':
            message.GRE = 'Tο συγκεκρικμένο email χρησιμοποιείται ήδη';
            message.ENG = 'This email address is already registered';
            break;
        case 'INVALID_CREDENTIALS':
            message.GRE = "Μη έγκυρο email ή κωδικός";
            message.ENG = "Invalid email or password";
            break;
        case 'INVALID_PIN':
            message.GRE = "Μη έγκυρο Pin";
            message.ENG = "Invalid Pin";
            break;
        case 'USER_NOT_FOUND':
            message.GRE = "Δεν υπάρχει χρήστης με το συγκεκριμένο email";
            message.ENG = "There is no user with that email";
            break;
        case 'SEND_VERIFICATION_CODE_ERROR':
            message.GRE = "Δε μπορέσαμε να στείλουμε μήνυμα στον αριθμό σου";
            message.ENG = "We could not send a message to your number";
            break;
        case 'INVALID_VERIFICATION_CODE':
            message.GRE = "Ο αριθμός επιβεβαίωσης είναι μη-έγκυρος ή ληγμένος";
            message.ENG = "Verification code is invalid or expired";
            break;
        case 'USER_ALREADY_VERIFIED':
            message.GRE = "Ο συγκεκριμένος χρήστης έχει ήδη επιβεβαιώσει τον αριθμό του";
            message.ENG = "User is already verified";
            break;
        case 'INVALID_AUTH_TOKEN':
            message.GRE = "Κάτι πήγε λάθος, δοκιμάστε πάλι σε λίγο"
            message.ENG = "Something went wrong, try again later"
            break;
        case 'VERIFICATIONS_CODES_LIMIT_REACHED':
            message.GRE = "Υπερβήκατε το όριο των κωδικών επιβεβαιωσής"
            message.ENG = "Too many verification codes sent"
            break;
        case 'PHONE_ALREADY_USED':
            message.GRE = "Ο αριθμός αυτός χρησιμοποιείται ήδη"
            message.ENG = "This phone is already used"
            break;
        case 'INVALID_TIME_TO_BE_DELIVERED_FOR_SCHEDULED_ORDER':
            message.GRE = "Η ώρα παράδοσης που επιλέξατε δεν υποστηρίζεται"
            message.ENG = "The delivery time you selected is not supported"
            break;
        case 'INVALID_DATA_FOR_ORDER':
            message.GRE = "Μη έγκυρα δεδομένα για την αλλαγή της κατάστασης της παραγγελίας"
            message.ENG = "Invalid data to change status for order"
            break;
        case 'INVALID_STATUS_FOR_ORDER':
            message.GRE = "Μη έγκυρη κατάσταση για αλλαγή παραγγελίας"
            message.ENG = "Invalid status to change order"
            break;
        case 'ORDER_NOT_PENDING_STATUS':
            message.GRE = "Μη έγκυρη κατάσταση για αλλαγή παραγγελίας"
            message.ENG = "Invalid status to change order"
            break;
        case 'ORDER_CANNOT_BE_REJECTED':
            message.GRE = "Μη έγκυρη κατάσταση για αλλαγή παραγγελίας"
            message.ENG = "Invalid status to change order"
            break;
        case 'INVALID_STATUS_FOR_ORDER_EXECUTION':
            message.GRE = "Αδυναμία αποδοχής παραγγελίας, επειδή έχει απορριφθεί ή έχει λήξει."
            message.ENG = "Cannot accept order, it has been declined or has expired."
            break;
        case 'ENDING_DATE_MUST_BE_AFTER_PICK_UP_DATE':
            message.GRE = "Η ημερομηνία επιστροφής πρέπει να είναι μετά την ημερομηνία παραλαβής."
            message.ENG = "Return date must be after pick up date."
            break;
        case 'MAX_ORDERS_REACHED_FOR_SPOT_FOR_THIS_DAY':
            message.GRE = "Το συγκεκριμένο τραπέζι/δωμάτιο έχει συμπληρώσει τον μέγιστο αριθμό παραγγελιών που μπορεί να πραγματοποιήσει σήμερα."
            message.ENG = "This table/room has reached the maximum number of orders that can be placed today."
            break;
        case 'MAX_ORDERS_REACHED_FOR_SPOT_FOR_DELIVERY_DATE':
            message.GRE = "Το συγκεκριμένο τραπέζι/δωμάτιο έχει συμπληρώσει τον μέγιστο αριθμό παραγγελιών που μπορεί να πραγματοποιήσει για την ημερομήνια παράδοσης που ορίσατε."
            message.ENG = "This table/room has reached the maximum number of orders that can be placed for the delivery date you specified."
            break;
        default:
            message.GRE = "Κάτι πήγε λάθος, δοκιμάστε ξανά"
            message.ENG = "Something went wrong, try again"
    }
    return message;
}

// below was taken from below link (2nd answer):
// https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone
async function getCurrentGreekDate() {
    return axios
        .get(`/utils/date`)
        .then((response) => {
            try {
                let dateStr = response.data;
                let dateFromServer = new Date(dateStr);
                let invdate = new Date(dateFromServer.toLocaleString('en-US', {
                    timeZone: "Europe/Athens"
                }));
                var diff = dateFromServer.getTime() - invdate.getTime();
                let greekDate = new Date(dateFromServer.getTime() - diff);
                return greekDate;
            } catch (error) {
                var here = new Date();
                var invdate = new Date(here.toLocaleString('en-US', {
                    timeZone: "Europe/Athens"
                }));
                var diff = here.getTime() - invdate.getTime();
                let greekDate = new Date(here.getTime() - diff);
                return greekDate;
            }
        })
        .catch((error) => {
            var here = new Date();
            var invdate = new Date(here.toLocaleString('en-US', {
                timeZone: "Europe/Athens"
            }));
            var diff = here.getTime() - invdate.getTime();
            let greekDate = new Date(here.getTime() - diff);
            return greekDate;
        });

}

export { getResponseMessage, getCurrentGreekDate };