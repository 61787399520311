<template>
  <div :class="{ accepted: accepted }" id="cookies-banner">
    <div id="cookies-inform">
      <h4 id="cookies-title">{{ labels.cookies.cookiesInfo[language] }}</h4>
      <p id="cookies-text">{{ labels.cookies.weUseCookies[language] }}</p>
    </div>
    <Button @click="accept" id="ok-button">{{ labels.cookies.alright[language] }}</Button>
  </div>
</template>

<script>
import Button from "./Button";
export default {
  name: "CookiesBanner",
  components: {
    Button,
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("cookies-banner").style.bottom = "5vh";
      document.getElementById("cookies-banner").style.right = "5vw";
    }, 1000);

    this.timeoutId = setTimeout(() => {
      this.accepted = true;
      localStorage.acceptedCookies = JSON.stringify(true)
    }, 4000)
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage;
    },
  },
  data() {
    return {
      accepted: false,
      timeoutId: undefined
    };
  },
  methods: {
    accept: function () {
      clearTimeout(this.timeoutId)
      this.accepted = true;
      setTimeout(() => localStorage.acceptedCookies = JSON.stringify(true), 1000)
    },
  },
};
</script>

<style scoped>
#cookies-banner {
  position: fixed;
  bottom: -150px;
  right: 5vw;
  max-width: 90vw;
  width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  border-radius: 8px;
  padding: 10px;
  background: white;
  background: rgb(25, 139, 74, 0.9);
  color: white;
  box-shadow: 0px -2px 4px rgb(0, 0, 0, 0.16), 0px -2px 4px rgb(0, 0, 0, 0.23);
  z-index: 202;
  transition: 0.5s ease-in-out;
}

.accepted {
  right: -354px !important;
}

#cookies-title {
  margin: 0;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
  opacity: 1 !important;
}

#cookies-text {
  font-size: 14px;
  opacity: 1 !important;
}

#ok-button {
  color: #198b4a;
  background: white;
  font-weight: 800;
  font-size: 12px;
  border-radius: 8px;
  opacity: 1 !important;
}
</style>