import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    storeId: -1,
    storeIsHotel: false,
    storeWithAllServices: null,
    numberOfServices: 1,
    isReadOnlyEnabled: false,
    service: {
      serviceId: -1,
      name: {
        GRE: undefined,
        ENG: undefined,
      },
      hours: {
        startTime: undefined,
        endTime: undefined,
        scheduledOrdersStartTime: undefined,
        scheduledOrdersEndTime: undefined,
      },
      scheduledOrdersEnabled: false,
      membershipEnabled: false,
      membershipPricesVisible: true,
      userHasMemberShip: false,
      type: '',
      delayedResponse: false,
      hidePhotosOfItems: false,
      maxItemsPerOrder: null
    },
    table: {
      id: -1,
      capacity: 0,
      section: undefined,
      type: undefined,
      isPda: false,
    },
    tableFamilySections: [],
    isInteractiveCatalogue: true,
    userData: {
      authToken: "",
      jwt: "",
      user: {},
    },
    categories: [],
    searchProductsInput: "",
    areCategoriesVisible: false,
    restaurantsWithTheirTables: [],
    adminAccounts: [],
    rollbackCategoriesState: [],
    areMobileCategoriesOpen: false,
    haveMobileCategoriesOpened: false,
    cart: [],
    nextItemId: 0,
    itemModal: {
      triggerBy: "",
      isEdit: false,
      isOpen: false,
      quantity: 1,
      comment: "",
      id: 0,
      indexOfItemInCart: 0,
      selectedItem: {},
      selectedSubOptions: []
    },
    happyHour: {
      isHappyHourModalOpen: false,
      discountPercentage: 0,
      startTime: null,
      endTime: null,
    },
    contest: {
      isContestModalOpen: false
    },
    cartPreview: {
      isCartPreviewOpen: false,
      hasCartPreviewOpened: false,
      hasItemBeenInserted: false,
    },
    order: {
      id: undefined,
      status: "",
      onHold: false,
      errorMessage: "",
      scheduled: false,
      dailyNumber: undefined,
    },
    orderComment: "",
    orderModal: {
      isOpen: false,
    },
    tableOrdersModalOpen: false,
    categoriesModalOpen: false,
    enter: {
      slideEntranceIn: false,
      slideEntranceOut: false,
      entrance: {
        success: undefined,
        message: "",
      },
      verification: {
        success: undefined,
        message: "",
      },
    },
    isLoading: true,
    profile: {
      changes: {
        phone: {
          slideChangePhoneIn: false,
          slideChangePhoneOut: false,
          change: {
            success: undefined,
            message: "",
          },
          verification: {
            success: undefined,
            message: "",
          },
        },
        success: undefined,
        message: "",
      },
      delete: {
        success: undefined,
        message: "",
      },
    },
    language: {
      isModalOpen: false,
      selectedLanguage: "GRE",
    },
    extraLanguages: [],
    selectedExtraLanguage: null,
    payment: {
      type: "cash",
      availableCards: [
        {
          id: 0,
          label: "Νέα κάρτα",
          selected: true,
        },
      ],
    },
    deleteCreditCardModal: {
      open: false,
      cardToDelete: {},
    },
    customItems: {},
    checkout: {
      availableTables: [],
      selectedTable: undefined,
      availableChargingRooms: [],
      selectedChargingRoom: undefined
    },
    delivery: {
      deliveryInfo: {
        address: undefined,
        area: undefined,
        postalCode: undefined,
        floor: undefined,
        doorbell: undefined,
      },
      isDeliveryInfoFormOpen: false,
      isDeliveryInfoFormDisabled: false,
      hasDeliveryDiscount: false,
      discountPercentage: 0,
      minimumPriceForDeliveryDiscount: 0,
    },
    currentGreekDate: undefined,
  },
  getters: {
    getStoreId: (state) => {
      return state.storeId;
    },

    isStoreHotel: (state) => {
      return state.storeIsHotel;
    },

    getNumberOfServices: (state) => {
      return state.numberOfServices;
    },

    getRestaurantsWithTheirTables: (state) => {
      return state.restaurantsWithTheirTables;
    },

    getAdminAccounts: (state) => {
      return state.adminAccounts;
    },

    getStoreWithAllServices: (state) => {
      return state.storeWithAllServices;
    },

    getIsReadOnlyEnabled: (state) => {
      return state.isReadOnlyEnabled;
    },

    getIsInteractiveCatalogue: (state) => {
      return state.isInteractiveCatalogue;
    },

    getSearchProductsInput: (state) => {
      return state.searchProductsInput;
    },

    getTableFamilySections: (state) => {
      return state.tableFamilySections;
    },

    getServiceId: (state) => {
      return state.service.serviceId;
    },

    getServiceName: (state) => {
      return state.service.name;
    },

    getServiceStartTime: (state) => {
      return state.service.hours.startTime;
    },

    getServiceEndTime: (state) => {
      return state.service.hours.endTime;
    },

    getServiceScheduledOrdersStartTime: (state) => {
      return state.service.hours.scheduledOrdersStartTime;
    },

    getServiceScheduledOrdersEndTime: (state) => {
      return state.service.hours.scheduledOrdersEndTime;
    },

    getServiceMembershipEnabled: (state) => {
      return state.service.membershipEnabled;
    },

    getUserHasMemberShipForService: (state) => {
      return state.service.userHasMemberShip;
    },

    getServiceMembershipPricesVisible: (state) => {
      return state.service.membershipPricesVisible;
    },

    getServiceType: (state) => {
      return state.service.type;
    },

    getServiceScheduledOrdersEnabled: (state) => {
      return state.service.scheduledOrdersEnabled;
    },

    getServiceDelayedResponse: (state) => {
      return state.service.delayedResponse;
    },

    getHidePhotosOfItems: (state) => {
      return state.service.hidePhotosOfItems;
    },

    getServiceMaxItemsPerOrder: (state) => {
      return state.service.maxItemsPerOrder;
    },

    getTableId: (state) => {
      return state.table.id;
    },

    getTablePda: (state) => {
      return state.table.isPda;
    },

    getTableCapacity: (state) => {
      return state.table.capacity;
    },

    getTableSection: (state) => {
      return state.table.section;
    },

    getTableType: (state) => {
      return state.table.type;
    },

    getUserData: (state) => {
      return state.userData;
    },

    getUser: (state) => {
      return state.userData.user;
    },

    getUserAuthToken: (state) => {
      return state.userData.authToken;
    },

    getUserJWT: (state) => {
      return state.userData.jwt;
    },

    getUserId: (state) => {
      return state.userData.user.id;
    },

    getUserName: (state) => {
      return state.userData.user.name;
    },

    getUserEmail: (state) => {
      return state.userData.user.email;
    },

    getUserPhone: (state) => {
      return state.userData.user.phone
        ? state.userData.user.phone.phoneNumber
        : "";
    },

    getUserISO2: (state) => {
      return state.userData.user.phone
        ? state.userData.user.phone.countryISO2
        : "";
    },

    isUserVerified: (state) => {
      return state.userData.user.verified;
    },

    userHasPhone: (state) => {
      return state.userData.user.phone != undefined;
    },

    hasUserOptedInForNewsletter: (state) => {
      return state.userData.user.optedInForNewsletter;
    },

    isLoggedIn: (state) => {
      return state.userData.authToken && state.userData.jwt;
    },

    getCategories: (state) => {
      return state.categories;
    },

    areCategoriesVisible: (state) => {
      return state.areCategoriesVisible;
    },

    getRollbackCategoriesState: (state) => {
      return state.rollbackCategoriesState;
    },

    getCartItems: (state) => {
      return state.cart;
    },

    getCartSize: (state) => {
      let totalQuantity = 0;
      state.cart.forEach((cartItem) => (totalQuantity += cartItem.quantity));
      return totalQuantity;
    },

    getLastItemInserted: (state) => {
      return state.cart.slice(-1)[0];
    },

    getIsCartPreviewOpen: (state) => {
      return state.cartPreview.isCartPreviewOpen;
    },

    getHasItemBeenInserted: (state) => {
      return state.cartPreview.hasItemBeenInserted;
    },

    getAreMobileCategoriesOpen: (state) => {
      return state.areMobileCategoriesOpen;
    },

    getHaveMobileCategoriesOpened: (state) => {
      return state.haveMobileCategoriesOpened;
    },

    getSelectedItem: (state) => {
      return state.itemModal.selectedItem;
    },

    getSelectedSubOptions: (state) => {
      return state.itemModal.selectedSubOptions;
    },

    getIndexOfItemInCart: (state) => {
      return state.itemModal.indexOfItemInCart;
    },

    getNextItemId: (state) => {
      return state.nextItemId;
    },

    getIsItemModalOpen: (state) => {
      return state.itemModal.isOpen;
    },

    getItemModalTrigger: (state) => {
      return state.itemModal.triggerBy;
    },

    getItemModalQuantity: (state) => {
      return state.itemModal.quantity;
    },

    getItemModalComment: (state) => {
      return state.itemModal.comment;
    },

    getItemModalId: (state) => {
      return state.itemModal.id;
    },

    getIsItemEditModal: (state) => {
      return state.itemModal.isEdit;
    },

    isHappyHourModalOpen: (state) => {
      return state.happyHour.isHappyHourModalOpen;
    },

    isContestModalOpen: (state) => {
      return state.contest.isContestModalOpen;
    },

    getHappyHourDiscountPercentage: (state) => {
      return state.happyHour.discountPercentage;
    },

    getHappyHourStartTime: (state) => {
      return state.happyHour.startTime;
    },

    getHappyHourEndTime: (state) => {
      return state.happyHour.endTime;
    },

    isInHappyHour: (state) => (currentDate) => {
      let currentHour =
        currentDate.getHours() > 9
          ? currentDate.getHours()
          : `0${currentDate.getHours()}`;
      let currentMinute =
        currentDate.getMinutes() > 9
          ? currentDate.getMinutes()
          : `0${currentDate.getMinutes()}`;
      let currentSecond =
        currentDate.getSeconds() > 9
          ? currentDate.getSeconds()
          : `0${currentDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let shouldShowHappyHour = false;
      if (state.happyHour.startTime < state.happyHour.endTime) {
        shouldShowHappyHour =
          state.happyHour.startTime < currentTime &&
          state.happyHour.endTime > currentTime;
      } else {
        shouldShowHappyHour =
          state.happyHour.startTime < currentTime ||
          state.happyHour.endTime > currentTime;
      }

      return shouldShowHappyHour;
    },

    getOrderComment: (state) => {
      return state.orderComment;
    },

    getOrderId: (state) => {
      return state.order.id;
    },

    getOrderStatus: (state) => {
      return state.order.status;
    },

    getOrderDailyNumber: (state) => {
      return state.order.dailyNumber;
    },

    getOrderErrorMessage: (state) => {
      return state.order.errorMessage;
    },

    getOrderScheduled: (state) => {
      return state.order.scheduled;
    },

    isOrderOnHold: (state) => {
      return state.order.onHold;
    },

    getTotalCost: (state) => {
      let totalCost = 0;
      state.cart.forEach((item) => {
        let subselectionsPrice = 0;
        if (item.item.subselections) {
          item.item.subselections.forEach((subselection) => {
            subselection.options.forEach((option) => {
              if (option.isSelected) {
                subselectionsPrice += option.price;
              }
            });
          });
        }

        let shouldAddMembershipPrice = state.service.membershipEnabled && item.item.memberPriceEnabled && item.item.membershipPrice != item.item.price && state.service.userHasMemberShip;
        let priceToBeAddedInTotelCost = shouldAddMembershipPrice ? item.item.membershipPrice : item.item.price;
        totalCost += (priceToBeAddedInTotelCost + subselectionsPrice) * item.quantity;
      });

      return totalCost;
    },

    getTotalCostWithoutMembershipPrices: (state) => {
      let totalCost = 0;
      state.cart.forEach((item) => {
        let subselectionsPrice = 0;
        if (item.item.subselections) {
          item.item.subselections.forEach((subselection) => {
            subselection.options.forEach((option) => {
              if (option.isSelected) {
                subselectionsPrice += option.price;
              }
            });
          });
        }
        totalCost += (item.item.price + subselectionsPrice) * item.quantity;
      });

      return totalCost;
    },

    getTotalMembershipCost: (state) => {
      let totalCost = 0;
      state.cart.forEach((cartItem) => {
        let subselectionsPrice = 0;
        if (cartItem.item.subselections) {
          cartItem.item.subselections.forEach((subselection) => {
            subselection.options.forEach((option) => {
              if (option.isSelected) {
                subselectionsPrice += option.price;
              }
            });
          });
        }
        if (cartItem.item.memberPriceEnabled && cartItem.item.price != cartItem.item.membershipPrice) {
          totalCost += (cartItem.item.membershipPrice + subselectionsPrice) * cartItem.quantity;
        } else {
          totalCost += (cartItem.item.price + subselectionsPrice) * cartItem.quantity;
        }
      });

      return totalCost;
    },

    hasAtleasOneCartItemMembershipPrice: (state) => {
      return state.cart.some(cartItem => cartItem.item.memberPriceEnabled && cartItem.item.price != cartItem.item.membershipPrice);
    },

    getSlideEntranceIn: (state) => {
      return state.enter.slideEntranceIn;
    },

    getSlideEntranceOut: (state) => {
      return state.enter.slideEntranceOut;
    },

    getEntranceSuccess: (state) => {
      return state.enter.entrance.success;
    },

    getEntranceMessage: (state) => {
      return state.enter.entrance.message;
    },

    getVerificationSuccess: (state) => {
      return state.enter.verification.success;
    },

    getVerificationMessage: (state) => {
      return state.enter.verification.message;
    },

    getIsLoading: (state) => {
      return state.isLoading;
    },

    getSlideChangePhoneIn: (state) => {
      return state.profile.changes.phone.slideChangePhoneIn;
    },

    getSlideChangePhoneOut: (state) => {
      return state.profile.changes.phone.slideChangePhoneOut;
    },

    getChangePhoneSuccess: (state) => {
      return state.profile.changes.phone.change.success;
    },

    getChangePhoneMessage: (state) => {
      return state.profile.changes.phone.change.message;
    },

    getChangeVerificationSuccess: (state) => {
      return state.profile.changes.phone.verification.success;
    },

    getChangeVerificationMessage: (state) => {
      return state.profile.changes.phone.verification.message;
    },

    getProfileChangesSuccess: (state) => {
      return state.profile.changes.success;
    },

    getProfileChangesMessage: (state) => {
      return state.profile.changes.message;
    },

    getDeleteUserSuccess: (state) => {
      return state.profile.delete.success;
    },

    getDeleteUserMessage: (state) => {
      return state.profile.delete.message;
    },

    getLanguage: (state) => {
      return state.language.selectedLanguage;
    },

    getExtraLanguages: (state) => {
      return state.extraLanguages;
    },

    getSelectedExtraLanguage: (state) => {
      return state.selectedExtraLanguage;
    },

    isLanguageModalOpen: (state) => {
      return state.language.isModalOpen;
    },

    getPaymentType: (state) => {
      return state.payment.type;
    },

    getSelectedCard: (state) => {
      return state.payment.availableCards.find((card) => card.selected);
    },

    getAvailableCards: (state) => {
      return state.payment.availableCards;
    },

    isDeleteCreditCardModalOpen: (state) => {
      return state.deleteCreditCardModal.open;
    },

    getCreditCardToDelete: (state) => {
      return state.deleteCreditCardModal.cardToDelete;
    },

    getCustomItems: (state) => {
      return state.customItems;
    },

    getCustomAcceptOrderImage: (state) => {
      return state.customItems.ACCEPTED_ORDER_IMG;
    },

    getCustomRejectOrderImage: (state) => {
      return state.customItems.REJECTED_ORDER_IMG;
    },

    getCustomTimedOutOrderImage: (state) => {
      return state.customItems.TIMED_OUT_ORDER_IMG;
    },

    getCustomErrorOrderImage: (state) => {
      return state.customItems.SERVER_ERROR_IMG;
    },

    getCustomPaymentFailedOrderImage: (state) => {
      return state.customItems.PAYMENT_FAILED_ORDER_IMG;
    },

    getAvailableTables: (state) => {
      return state.checkout.availableTables;
    },

    getSelectedTable: (state) => {
      return state.checkout.selectedTable;
    },

    getAvailableChargingRooms: (state) => {
      return state.checkout.availableChargingRooms;
    },

    getSelectedChargingRoom: (state) => {
      return state.checkout.selectedChargingRoom;
    },

    getDeliveryInfo: (state) => {
      return state.delivery.deliveryInfo;
    },

    getDeliveryAddress: (state) => {
      return state.delivery.deliveryInfo.address;
    },

    getDeliveryArea: (state) => {
      return state.delivery.deliveryInfo.area;
    },

    getDeliveryPostalCode: (state) => {
      return state.delivery.deliveryInfo.postalCode;
    },

    getDeliveryFloor: (state) => {
      return state.delivery.deliveryInfo.floor;
    },

    getDeliveryDoorbell: (state) => {
      return state.delivery.deliveryInfo.doorbell;
    },

    isDeliveryInfoFormOpen: (state) => {
      return state.delivery.isDeliveryInfoFormOpen;
    },

    isDeliveryInfoFormDisabled: (state) => {
      return state.delivery.isDeliveryInfoFormDisabled;
    },

    hasDeliveryDiscount: (state) => {
      return state.delivery.hasDeliveryDiscount;
    },

    getDeliveryDiscountPercentage: (state) => {
      return state.delivery.discountPercentage;
    },

    getMinimumPriceForDeliveryDiscount: (state) => {
      return state.delivery.minimumPriceForDeliveryDiscount;
    },

    getCurrentGreekDate: (state) => {
      return state.currentGreekDate;
    },
  },
  mutations: {
    setStoreId: (state, id) => {
      state.storeId = id;
    },

    setStoreIsHotel: (state, isHotel) => {
      state.storeIsHotel = isHotel;
    },

    setNumberOfServices: (state, amount) => {
      state.numberOfServices = amount;
    },

    setRestaurantsWithTheirTables: (state, restaurantsWithTheirTables) => {
      state.restaurantsWithTheirTables = restaurantsWithTheirTables;
    },

    setAdminAccounts: (state, adminAccounts) => {
      state.adminAccounts = adminAccounts;
    },

    setStoreWithAllServices: (state, storeWithAllServices) => {
      state.storeWithAllServices = storeWithAllServices;
    },

    setIsReadOnlyEnabled: (state, isEnabled) => {
      state.isReadOnlyEnabled = isEnabled;
    },

    setIsInteractiveCatalogue: (state, isInteractiveCatalogue) => {
      state.isInteractiveCatalogue = isInteractiveCatalogue;
    },

    setSearchProductsInput: (state, searchProductsInput) => {
      state.searchProductsInput = searchProductsInput;
    },

    setTableFamilySections: (state, tableFamilySections) => {
      state.tableFamilySections = tableFamilySections;
    },

    setServiceId: (state, id) => {
      state.service.serviceId = id;
    },

    setServiceName: (state, name) => {
      state.service.name = name;
    },

    setServiceStartTime: (state, time) => {
      state.service.hours.startTime = time;
    },

    setServiceEndTime: (state, time) => {
      state.service.hours.endTime = time;
    },

    setServiceScheduledOrdersStartTime: (state, scheduledOrdersStartTime) => {
      state.service.hours.scheduledOrdersStartTime = scheduledOrdersStartTime;
    },

    setServiceScheduledOrdersEndTime: (state, scheduledOrdersEndTime) => {
      state.service.hours.scheduledOrdersEndTime = scheduledOrdersEndTime;
    },

    setServiceMembershipEnabled: (state, membershipEnabled) => {
      state.service.membershipEnabled = membershipEnabled;
    },

    setUserHasMembershipForService: (state, userHasMemberShip) => {
      state.service.userHasMemberShip = userHasMemberShip;
    },

    setServiceMembershipPricesVisible: (state, membershipPricesVisible) => {
      state.service.membershipPricesVisible = membershipPricesVisible;
    },

    setServiceType: (state, type) => {
      state.service.type = type;
    },

    setServiceScheduledOrdersEnabled: (state, scheduledOrdersEnabled) => {
      state.service.scheduledOrdersEnabled = scheduledOrdersEnabled;
    },

    setServiceDelayedResponse: (state, delayedResponse) => {
      state.service.delayedResponse = delayedResponse;
    },

    setHidePhotosOfItems: (state, hidePhotosOfItems) => {
      state.service.hidePhotosOfItems = hidePhotosOfItems;
    },

    setServiceMaxItemsPerOrder: (state, maxItemsPerOrder) => {
      state.service.maxItemsPerOrder = maxItemsPerOrder;
    },

    setTableId: (state, id) => {
      state.table.id = id;
    },

    setTablePda: (state, isPda) => {
      state.table.isPda = isPda;
    },

    setTableCapacity: (state, capacity) => {
      state.table.capacity = capacity;
    },

    setTableSection: (state, section) => {
      state.table.section = section;
    },

    setTableType: (state, type) => {
      state.table.type = type;
    },

    setUserData: (state, userData) => {
      state.userData = userData;
      localStorage.userData = JSON.stringify(userData);
    },

    setUser: (state, user) => {
      state.userData.user = user;
      localStorage.userData = JSON.stringify(state.userData);
    },

    setUserAuthToken: (state, authToken) => {
      state.userData.authToken = authToken;
      localStorage.userData = JSON.stringify(state.userData);
    },

    setUserJWT: (state, jwt) => {
      state.userData.jwt = jwt;
      localStorage.userData = JSON.stringify(state.userData);
    },

    verifyUser: (state) => {
      state.userData.user.verified = true;
      localStorage.userData = JSON.stringify(state.userData);
    },

    optUserInForNewsletter: (state) => {
      state.userData.user.optedInForNewsletter = true;
      localStorage.userData = JSON.stringify(state.userData);
    },

    optUserOutForNewsletter: (state) => {
      state.userData.user.optedInForNewsletter = false;
      localStorage.userData = JSON.stringify(state.userData);
    },

    addCategory: (state, category) => {
      if (category.id == -1 && !category.isActive) {
        state.categories.forEach((categoryItem) => {
          if (categoryItem.id == -1) {
            categoryItem.isActive = true;
          } else {
            categoryItem.isActive = false;
          }
        });
      } else if (category.id != -1) {
        state.categories.forEach((categoryItem) => {
          if (categoryItem.id == -1) {
            categoryItem.isActive = false;
          } else if (categoryItem.id == category.id) {
            categoryItem.isActive = true;
          }
        });
      }
    },

    removeCategory: (state, category) => {
      if (category.id != -1) {
        state.categories.forEach((categoryItem) => {
          if (categoryItem.id == category.id) {
            categoryItem.isActive = false;
          }
        });
      }
      let activeCategories = state.categories.filter(
        (category) => category.isActive === true
      );
      if (activeCategories.length === 0) {
        state.categories.forEach((category) => {
          if (category.id == -1) {
            category.isActive = true;
          }
        });
      }
    },

    goToCategory: (state, category) => {
      state.categories.forEach((categoryItem) => {
        if (category.id == categoryItem.id) {
          categoryItem.isActive = true;
        } else {
          categoryItem.isActive = false;
        }
      });
    },

    setCategories: (state, categories) => {
      state.categories = categories;
    },

    categoriesAreVisible: (state) => {
      state.areCategoriesVisible = true;
    },

    categoriesAreNotVisible: (state) => {
      state.areCategoriesVisible = false;
    },

    setRollbackCategoriesState: (state, rollbackCategoriesState) => {
      state.rollbackCategoriesState = rollbackCategoriesState;
    },

    setCart: (state, cart) => {
      state.cart = cart;
      window.localStorage.cart = JSON.stringify(state.cart);
    },

    addToCart: (state, item) => {
      state.cart.push(item);
      window.localStorage.cart = JSON.stringify(state.cart);
    },

    removeFromCart: (state, item) => {
      var index = state.cart.indexOf(item);
      if (index !== -1) {
        state.cart.splice(index, 1);
        window.localStorage.cart = JSON.stringify(state.cart);
      }
    },

    removeFromCartByIndex: (state, index) => {
      if (index >= 0) {
        state.cart.splice(index, 1);
        window.localStorage.cart = JSON.stringify(state.cart);
      }
    },

    resetMobileCartStatusIfNeeded: (state) => {
      if (state.cart.length == 0) {
        state.hasItemBeenInserted = false;
      }
    },

    setItemModalTrigger: (state, trigger) => {
      state.itemModal.triggerBy = trigger;
    },

    openItemModal: (state) => {
      state.itemModal.isOpen = true;
    },

    closeItemModal: (state) => {
      state.itemModal.isOpen = false;
    },

    openCartPreview: (state) => {
      state.cartPreview.isCartPreviewOpen = true;
    },

    closeCartPreview: (state) => {
      state.cartPreview.isCartPreviewOpen = false;
    },

    cartPreviewHasOpened: (state) => {
      state.cartPreview.hasCartPreviewOpened = true;
    },

    cartPreviewHasNotOpened: (state) => {
      state.cartPreview.hasCartPreviewOpened = false;
    },

    itemHasBeenInserted: (state) => {
      state.cartPreview.hasItemBeenInserted = true;
    },

    itemHasNotBeenInserted: (state) => {
      state.cartPreview.hasItemBeenInserted = false;
    },

    openMobileCategories: (state) => {
      state.areMobileCategoriesOpen = true;
    },

    closeMobileCategories: (state) => {
      state.areMobileCategoriesOpen = false;
    },

    mobileCategoriesHaveOpened: (state) => {
      state.haveMobileCategoriesOpened = true;
    },

    mobileCategoriesHaveNotOpened: (state) => {
      state.haveMobileCategoriesOpened = false;
    },

    setSelectedItem: (state, item) => {
      state.itemModal.selectedItem = item;
    },

    setSelectedSubOptions: (state, selectedSubOptions) => {
      state.itemModal.selectedSubOptions = selectedSubOptions;
    },

    setIndexOfItemInCart: (state, indexOfItemInCart) => {
      state.itemModal.indexOfItemInCart = indexOfItemInCart;
    },

    incrementNextItemId: (state) => {
      state.nextItemId++;
      localStorage.nextItemID = JSON.stringify(state.nextItemId);
    },

    setNextItemID: (state, nextItemId) => {
      state.nextItemId = nextItemId;
    },

    setItemModalQuantity: (state, quantity) => {
      state.itemModal.quantity = quantity;
    },

    incrementItemModalQuantity: (state) => {
      state.itemModal.quantity++;
    },

    decrementItemModalQuantity: (state, isForCartUpdate) => {
      if (isForCartUpdate) {
        if (state.itemModal.quantity > 0) {
          state.itemModal.quantity--;
        }
      } else {
        if (state.itemModal.quantity > 1) {
          state.itemModal.quantity--;
        }
      }

    },

    setItemModalComment: (state, comment) => {
      state.itemModal.comment = comment;
    },

    setItemModalId: (state, id) => {
      state.itemModal.id = id;
    },

    setIsItemEditModalTrue: (state) => {
      state.itemModal.isEdit = true;
    },

    setIsNotItemEditModal: (state) => {
      state.itemModal.isEdit = false;
    },

    editItem: (state, newDetails) => {
      state.cart.forEach((cartItem) => {
        if (cartItem.id == newDetails.id) {
          cartItem.item = newDetails.item;
          cartItem.comment = newDetails.comment;
          cartItem.quantity = newDetails.quantity;
          window.localStorage.cart = JSON.stringify(state.cart);
        }
      });
    },

    triggerSubselectionOption: (state, item) => {
      state.itemModal.selectedItem.subselections.forEach((subselection) => {
        if (subselection.id == item.categoryId) {
          subselection.options.forEach((subselectionOption) => {
            if (subselectionOption.id == item.option.id) {
              if (
                subselection.mandatory &&
                subselectionOption.isSelected &&
                subselection.options.filter((option) => option.isSelected)
                  .length == 1
              ) {
                subselectionOption.isSelected = true;
              } else {
                subselectionOption.isSelected = !subselectionOption.isSelected;
              }
            } else {
              if (subselection.type == "RADIO_BUTTON") {
                subselectionOption.isSelected = false;
              }
            }
          });
        }
      });
    },

    openHappyHourModal: (state) => {
      state.happyHour.isHappyHourModalOpen = true;
    },

    closeHappyHourModal: (state) => {
      state.happyHour.isHappyHourModalOpen = false;
    },

    setHappyHourDiscountPercentage: (state, discountPercentage) => {
      state.happyHour.discountPercentage = discountPercentage;
    },

    setHappyHourStartTime: (state, startTime) => {
      state.happyHour.startTime = startTime;
    },

    setHappyHourEndTime: (state, endTime) => {
      state.happyHour.endTime = endTime;
    },

    openContestModal: (state) => {
      state.contest.isContestModalOpen = true;
    },

    closeContestModal: (state) => {
      state.contest.isContestModalOpen = false;
    },

    setOrderComment: (state, comment) => {
      state.orderComment = comment;
    },

    setOrderId: (state, id) => {
      state.order.id = id;
    },

    setOrderStatus: (state, status) => {
      state.order.status = status;
    },

    setOrderDailyNumber: (state, dailyNumber) => {
      state.order.dailyNumber = dailyNumber;
    },

    setOrderErrorMessage: (state, errorMessage) => {
      state.order.errorMessage = errorMessage;
    },

    setOrderScheduled: (state, scheduled) => {
      state.order.scheduled = scheduled;
    },

    resetOrderStatus: (state) => {
      state.order.status = "";
    },

    putOrderOnHold: (state) => {
      state.order.onHold = true;
    },

    putOrderOffHold: (state) => {
      state.order.onHold = false;
    },

    openOrderModal: (state) => {
      state.orderModal.isOpen = true;
    },

    closeOrderModal: (state) => {
      state.orderModal.isOpen = false;
    },

    openTableOrdersModal: (state) => {
      state.tableOrdersModalOpen = true;
    },

    closeTableOrdersModal: (state) => {
      state.tableOrdersModalOpen = false;
    },

    openCategoriesModal: (state) => {
      state.categoriesModalOpen = true;
    },

    closeCategoriesModal: (state) => {
      state.categoriesModalOpen = false;
    },

    emptyCart: (state) => {
      state.cart = [];
      state.nextItemId = 0;
      localStorage.nextItemID = JSON.stringify(state.nextItemId);
      window.localStorage.cart = JSON.stringify(state.cart);
    },

    shouldSlideEntranceIn: (state) => {
      state.enter.slideEntranceIn = true;
    },

    shouldNotSlideEntranceIn: (state) => {
      state.enter.slideEntranceIn = false;
    },

    shouldSlideEntranceOut: (state) => {
      state.enter.slideEntranceOut = true;
    },

    shouldNotSlideEntranceOut: (state) => {
      state.enter.slideEntranceOut = false;
    },

    successfullEntrance: (state) => {
      state.enter.entrance.success = true;
    },

    failedEntrance: (state) => {
      state.enter.entrance.success = false;
    },

    resetEntranceState: (state) => {
      state.enter.entrance.success = undefined;
    },

    setEntranceMessage: (state, message) => {
      state.enter.entrance.message = message;
    },

    successfullVerification: (state) => {
      state.enter.verification.success = true;
    },

    failedVerification: (state) => {
      state.enter.verification.success = false;
    },

    resetVerificationState: (state) => {
      state.enter.verification.success = undefined;
    },

    setVerificationMessage: (state, message) => {
      state.enter.verification.message = message;
    },

    setIsLoading: (state) => {
      state.isLoading = true;
    },

    setIsNotLoading: (state) => {
      state.isLoading = false;
    },

    shouldSlideChangePhoneIn: (state) => {
      state.profile.changes.phone.slideChangePhoneIn = true;
    },

    shouldNotSlideChangePhoneIn: (state) => {
      state.profile.changes.phone.slideChangePhoneIn = false;
    },

    shouldSlideChangePhoneOut: (state) => {
      state.profile.changes.phone.slideChangePhoneOut = true;
    },

    shouldNotSlideChangePhoneOut: (state) => {
      state.profile.changes.phone.slideChangePhoneOut = false;
    },

    successfullChangePhone: (state) => {
      state.profile.changes.phone.change.success = true;
    },

    failedChangePhone: (state) => {
      state.profile.changes.phone.change.success = false;
    },

    resetChangePhoneState: (state) => {
      state.profile.changes.phone.change.success = undefined;
    },

    setChangePhoneMessage: (state, message) => {
      state.profile.changes.phone.change.message = message;
    },

    successfullChangeVerification: (state) => {
      state.profile.changes.phone.verification.success = true;
    },

    failedChangeVerification: (state) => {
      state.profile.changes.phone.verification.success = false;
    },

    resetChangeVerificationState: (state) => {
      state.profile.changes.phone.verification.success = undefined;
    },

    setChangeVerificationMessage: (state, message) => {
      state.profile.changes.phone.verification.message = message;
    },

    successfullProfileChanges: (state) => {
      state.profile.changes.success = true;
    },

    failedProfileChanges: (state) => {
      state.profile.changes.success = false;
    },

    resetProfileChangesState: (state) => {
      state.profile.changes.success = undefined;
    },

    setProfileChangesMessage: (state, message) => {
      state.profile.changes.message = message;
    },

    successfullUserDeletion: (state) => {
      state.profile.delete.success = true;
    },

    failedUserDeletion: (state) => {
      state.profile.delete.success = false;
    },

    goToDefaultUserDeleteState: (state) => {
      state.profile.delete.success = undefined;
    },

    setDeleteUserMessage: (state, message) => {
      state.profile.delete.message = message;
    },

    setExtraLanguages: (state, extraLanguages) => {
      state.extraLanguages = extraLanguages;
    },

    setSelectedExtraLanguage: (state, selectedExtraLanguage) => {
      // we change the default selected language
      // to english for the texts of the app
      state.language.selectedLanguage = 'ENG';
      state.selectedExtraLanguage = selectedExtraLanguage;
    },

    setLanguage: (state, language) => {
      state.selectedExtraLanguage = null;
      state.language.selectedLanguage = language;
      localStorage.language = JSON.stringify(language);
    },

    setLanguageOnlyToMemory: (state, language) => {
      state.selectedExtraLanguage = null;
      state.language.selectedLanguage = language;
    },

    setDefaultLanguage: (state, language) => {
      if (!localStorage.language) {
        state.language.selectedLanguage = language;
      }
    },

    openLanguageModal: (state) => {
      state.language.isModalOpen = true;
    },

    closeLanguageModal: (state) => {
      state.language.isModalOpen = false;
    },

    translateAllCategoriesOption: (state, translation) => {
      state.categories.forEach((category) => {
        if (category.id === -1) {
          category.name = translation;
        }
      });
    },

    setPaymentType: (state, type) => {
      state.payment.type = type;
    },

    selectCard: (state, id) => {
      state.payment.availableCards.forEach((card) => {
        if (card.id == id) {
          card.selected = true;
        } else {
          card.selected = false;
        }
      });
    },

    setAvailableCards: (state, cards) => {
      let availableCards = [
        {
          id: 0,
          label: "Νέα κάρτα",
          selected: true,
        },
      ];
      let counter = 1;

      cards.forEach((card) => {
        card.id = counter++;
        card.label = `**** ${card.lastFourDigits}`;
        card.selected = false;
        availableCards.push(card);
      });

      state.payment.availableCards = availableCards;
    },

    deleteCard: (state, card) => {
      let newCards = [];

      state.payment.availableCards.forEach((availableCard) => {
        if (
          !availableCard.cardToken ||
          availableCard.cardToken != card.cardToken
        ) {
          newCards.push(availableCard);
        }
      });

      state.payment.availableCards = newCards;
    },

    openDeleteCreditCardModal: (state) => {
      state.deleteCreditCardModal.open = true;
    },

    closeDeleteCreditCardModal: (state) => {
      state.deleteCreditCardModal.open = false;
    },

    setCreditCardToDelete: (state, card) => {
      state.deleteCreditCardModal.cardToDelete = card;
    },

    setCustomItems: (state, customItems) => {
      state.customItems = customItems;
    },

    setAvailableTables: (state, tables) => {
      state.checkout.availableTables = tables;
    },

    setSelectedTable: (state, table) => {
      state.checkout.selectedTable = table;
    },

    setAvailableChargingRooms: (state, chargingRooms) => {
      state.checkout.availableChargingRooms = chargingRooms;
    },

    setSelectedChargingRoom: (state, chargingRoom) => {
      state.checkout.selectedChargingRoom = chargingRoom;
    },

    setDeliveryInfo: (state, deliveryInfo) => {
      state.delivery.deliveryInfo = deliveryInfo;
      localStorage.deliveryInfo = JSON.stringify(state.delivery.deliveryInfo);
    },

    setDeliveryAddress: (state, data) => {
      state.delivery.deliveryInfo.address = data.address;

      if (data.shouldSave) {
        localStorage.deliveryInfo = JSON.stringify(state.delivery.deliveryInfo);
      }
    },

    setDeliveryArea: (state, data) => {
      state.delivery.deliveryInfo.area = data.area;
      if (data.shouldSave) {
        localStorage.deliveryInfo = JSON.stringify(state.delivery.deliveryInfo);
      }
    },

    setDeliveryPostalCode: (state, data) => {
      state.delivery.deliveryInfo.postalCode = data.postalCode;
      if (data.shouldSave) {
        localStorage.deliveryInfo = JSON.stringify(state.delivery.deliveryInfo);
      }
    },

    setDeliveryFloor: (state, data) => {
      state.delivery.deliveryInfo.floor = data.floor;

      if (data.shouldSave) {
        localStorage.deliveryInfo = JSON.stringify(state.delivery.deliveryInfo);
      }
    },

    setDeliveryDoorbell: (state, data) => {
      state.delivery.deliveryInfo.doorbell = data.doorbell;

      if (data.shouldSave) {
        localStorage.deliveryInfo = JSON.stringify(state.delivery.deliveryInfo);
      }
    },

    deliveryInfoFormIsOpen: (state) => {
      state.delivery.isDeliveryInfoFormOpen = true;
    },

    deliveryInfoFormIsNotOpen: (state) => {
      state.delivery.isDeliveryInfoFormOpen = false;
    },

    disableDeliveryInfoForm: (state) => {
      state.delivery.isDeliveryInfoFormDisabled = true;
    },

    enableDeliveryInfoForm: (state) => {
      state.delivery.isDeliveryInfoFormDisabled = false;
    },

    doesHaveDeliveryDiscount: (state) => {
      state.delivery.hasDeliveryDiscount = true;
    },

    doesNotHaveDeliveryDiscount: (state) => {
      state.delivery.hasDeliveryDiscount = false;
    },

    setDeliveryDiscountPercentage: (state, discountPercentage) => {
      state.delivery.discountPercentage = discountPercentage;
    },

    setMinimumPriceForDeliveryDiscount: (state, minimumPriceForDeliveryDiscount) => {
      state.delivery.minimumPriceForDeliveryDiscount = minimumPriceForDeliveryDiscount;
    },


    setCurrentGreekDate: (state, currentGreekDate) => {
      state.currentGreekDate = currentGreekDate;
    },
  },
  actions: {
    setStoreId: (context, id) => {
      context.commit("setStoreId", id);
    },

    setStoreIsHotel: (context, isHotel) => {
      context.commit("setStoreIsHotel", isHotel);
    },

    setRestaurantsWithTheirTables: (context, restaurantsWithTheirTables) => {
      context.commit("setRestaurantsWithTheirTables", restaurantsWithTheirTables);
    },

    setAdminAccounts: (context, adminAccounts) => {
      context.commit("setAdminAccounts", adminAccounts);
    },

    setNumberOfServices: (context, amount) => {
      context.commit("setNumberOfServices", amount);
    },

    setStoreWithAllServices: (context, storeWithAllServices) => {
      context.commit("setStoreWithAllServices", storeWithAllServices);
    },

    setIsReadOnlyEnabled: (context, isEnabled) => {
      context.commit("setIsReadOnlyEnabled", isEnabled);
    },

    setIsInteractiveCatalogue: (context, isInteractiveCatalogue) => {
      context.commit("setIsInteractiveCatalogue", isInteractiveCatalogue);
    },

    setSearchProductsInput: (context, searchProductsInput) => {
      context.commit("setSearchProductsInput", searchProductsInput);
    },

    setTableFamilySections: (context, tableFamilySections) => {
      context.commit("setTableFamilySections", tableFamilySections);
    },

    setServiceId: (context, id) => {
      context.commit("setServiceId", id);
    },

    setServiceMembershipEnabled: (context, membershipEnabled) => {
      context.commit("setServiceMembershipEnabled", membershipEnabled);
    },

    setUserHasMembershipForService: (context, userHasMemberShip) => {
      context.commit("setUserHasMembershipForService", userHasMemberShip);
    },


    setServiceMembershipPricesVisible: (context, membershipPricesVisible) => {
      context.commit("setServiceMembershipPricesVisible", membershipPricesVisible);
    },

    setServiceType: (context, type) => {
      context.commit("setServiceType", type);
    },

    setServiceScheduledOrdersEnabled: (context, scheduledOrdersEnabled) => {
      context.commit("setServiceScheduledOrdersEnabled", scheduledOrdersEnabled);
    },

    setServiceDelayedResponse: (context, delayedResponse) => {
      context.commit("setServiceDelayedResponse", delayedResponse);
    },

    setHidePhotosOfItems: (context, hidePhotosOfItems) => {
      context.commit("setHidePhotosOfItems", hidePhotosOfItems);
    },

    setServiceMaxItemsPerOrder: (context, maxItemsPerOrder) => {
      context.commit("setServiceMaxItemsPerOrder", maxItemsPerOrder);
    },

    setServiceName: (context, name) => {
      context.commit("setServiceName", name);
    },

    setServiceStartTime: (context, time) => {
      context.commit("setServiceStartTime", time);
    },

    setServiceEndTime: (context, time) => {
      context.commit("setServiceEndTime", time);
    },

    setServiceScheduledOrdersStartTime: (context, scheduledOrdersStartTime) => {
      context.commit("setServiceScheduledOrdersStartTime", scheduledOrdersStartTime);
    },

    setServiceScheduledOrdersEndTime: (context, scheduledOrdersEndTime) => {
      context.commit("setServiceScheduledOrdersEndTime", scheduledOrdersEndTime);
    },

    setTableId: (context, id) => {
      context.commit("setTableId", id);
    },

    setTablePda: (context, isPda) => {
      context.commit("setTablePda", isPda);
    },

    setTableCapacity: (context, capacity) => {
      context.commit("setTableCapacity", capacity);
    },

    setTableSection: (context, section) => {
      context.commit("setTableSection", section);
    },

    setTableType: (context, type) => {
      context.commit("setTableType", type);
    },

    setUserData: (context, userData) => {
      context.commit("setUserData", userData);
    },

    setUser: (context, user) => {
      context.commit("setUser", user);
    },

    setUserAuthToken: (context, authToken) => {
      context.commit("setUserAuthToken", authToken);
    },

    setUserJWT: (context, jwt) => {
      context.commit("setUserJWT", jwt);
    },

    verifyUser: (context) => {
      context.commit("verifyUser");
    },

    optUserInForNewsletter: (context) => {
      context.commit("optUserInForNewsletter");
    },

    optUserOutForNewsletter: (context) => {
      context.commit("optUserOutForNewsletter");
    },

    addCategory: (context, category) => {
      context.commit("addCategory", category);
    },

    removeCategory: (context, category) => {
      context.commit("removeCategory", category);
    },

    goToCategory: (context, category) => {
      context.commit("goToCategory", category);
    },

    setCategories: (context, categories) => {
      context.commit("setCategories", categories);
    },

    categoriesAreVisible: (context) => {
      context.commit("categoriesAreVisible");
    },

    categoriesAreNotVisible: (context) => {
      context.commit("categoriesAreNotVisible");
    },

    setRollbackCategoriesState: (context, rollbackCategoriesState) => {
      context.commit("setRollbackCategoriesState", rollbackCategoriesState);
    },

    setCart: (context, cart) => {
      context.commit("setCart", cart);
    },

    addToCart: (context, item) => {
      context.commit("addToCart", item);
    },

    removeFromCart: (context, item) => {
      context.commit("removeFromCart", item);
      context.commit("resetMobileCartStatusIfNeeded");
    },

    removeFromCartByIndex: (context, item) => {
      context.commit("removeFromCartByIndex", item);
      context.commit("resetMobileCartStatusIfNeeded");
    },

    setItemModalTrigger: (context, trigger) => {
      context.commit("setItemModalTrigger", trigger);
    },

    openItemModal: (context) => {
      context.commit("openItemModal");
    },

    closeItemModal: (context) => {
      context.commit("closeItemModal");
    },

    openCartPreview: (context) => {
      context.commit("openCartPreview");
    },

    closeCartPreview: (context) => {
      context.commit("closeCartPreview");
    },

    cartPreviewHasOpened: (context) => {
      context.commit("cartPreviewHasOpened");
    },

    cartPreviewHasNotOpened: (context) => {
      context.commit("cartPreviewHasNotOpened");
    },

    itemHasBeenInserted: (context) => {
      context.commit("itemHasBeenInserted");
    },

    itemHasNotBeenInserted: (context) => {
      context.commit("itemHasNotBeenInserted");
    },

    openMobileCategories: (context) => {
      context.commit("openMobileCategories");
    },

    closeMobileCategories: (context) => {
      context.commit("closeMobileCategories");
    },

    mobileCategoriesHaveOpened: (context) => {
      context.commit("mobileCategoriesHaveOpened");
    },

    mobileCategoriesHaveNotOpened: (context) => {
      context.commit("mobileCategoriesHaveNotOpened");
    },

    setSelectedItem: (context, item) => {
      context.commit("setSelectedItem", item);
    },

    setSelectedSubOptions: (context, selectedSubOptions) => {
      context.commit("setSelectedSubOptions", selectedSubOptions);
    },

    setIndexOfItemInCart: (context, indexOfItemInCart) => {
      context.commit("setIndexOfItemInCart", indexOfItemInCart);
    },

    incrementNextItemId: (context) => {
      context.commit("incrementNextItemId");
    },

    setNextItemID: (context, nextItemID) => {
      context.commit("setNextItemID", nextItemID);
    },

    setItemModalQuantity: (context, quantity) => {
      context.commit("setItemModalQuantity", quantity);
    },

    incrementItemModalQuantity: (context) => {
      context.commit("incrementItemModalQuantity");
    },

    decrementItemModalQuantity: (context, isForCartUpdate) => {
      context.commit("decrementItemModalQuantity", isForCartUpdate);
    },

    setItemModalComment: (context, comment) => {
      context.commit("setItemModalComment", comment);
    },

    setItemModalId: (context, id) => {
      context.commit("setItemModalId", id);
    },

    setIsItemEditModalTrue: (context) => {
      context.commit("setIsItemEditModalTrue");
    },

    setIsNotItemEditModal: (context) => {
      context.commit("setIsNotItemEditModal");
    },

    editItem: (context, newDetails) => {
      context.commit("editItem", newDetails);
    },

    triggerSubselectionOption: (context, item) => {
      context.commit("triggerSubselectionOption", item);
    },

    openHappyHourModal: (context) => {
      context.commit("openHappyHourModal");
    },

    closeHappyHourModal: (context) => {
      context.commit("closeHappyHourModal");
    },

    setHappyHourDiscountPercentage: (context, discountPercentage) => {
      context.commit("setHappyHourDiscountPercentage", discountPercentage);
    },

    setHappyHourStartTime: (context, startTime) => {
      context.commit("setHappyHourStartTime", startTime);
    },

    setHappyHourEndTime: (context, endTime) => {
      context.commit("setHappyHourEndTime", endTime);
    },

    openContestModal: (context) => {
      context.commit("openContestModal");
    },

    closeContestModal: (context) => {
      context.commit("closeContestModal");
    },

    setOrderComment: (context, comment) => {
      context.commit("setOrderComment", comment);
    },

    setOrderId: (context, id) => {
      context.commit("setOrderId", id);
    },

    setOrderStatus: (context, status) => {
      context.commit("setOrderStatus", status);
    },

    setOrderDailyNumber: (context, dailyNumber) => {
      context.commit("setOrderDailyNumber", dailyNumber);
    },

    setOrderErrorMessage: (context, errorMessage) => {
      context.commit("setOrderErrorMessage", errorMessage);
    },

    setOrderScheduled: (context, scheduled) => {
      context.commit("setOrderScheduled", scheduled);
    },

    resetOrderStatus: (context) => {
      context.commit("resetOrderStatus");
    },

    putOrderOnHold: (context) => {
      context.commit("putOrderOnHold");
    },

    putOrderOffHold: (context) => {
      context.commit("putOrderOffHold");
    },

    openOrderModal: (context) => {
      context.commit("openOrderModal");
    },

    closeOrderModal: (context) => {
      context.commit("closeOrderModal");
    },

    openTableOrdersModal: (context) => {
      context.commit("openTableOrdersModal");
    },

    closeTableOrdersModal: (context) => {
      context.commit("closeTableOrdersModal");
    },

    openCategoriesModal: (context) => {
      context.commit("openCategoriesModal");
    },

    closeCategoriesModal: (context) => {
      context.commit("closeCategoriesModal");
    },

    emptyCart: (context) => {
      context.commit("emptyCart");
      context.commit("resetMobileCartStatusIfNeeded");
    },

    shouldSlideEntranceIn: (context) => {
      context.commit("shouldSlideEntranceIn");
    },

    shouldNotSlideEntranceIn: (context) => {
      context.commit("shouldNotSlideEntranceIn");
    },

    shouldSlideEntranceOut: (context) => {
      context.commit("shouldSlideEntranceOut");
    },

    shouldNotSlideEntranceOut: (context) => {
      context.commit("shouldNotSlideEntranceOut");
    },

    successfullEntrance: (context) => {
      context.commit("successfullEntrance");
    },

    failedEntrance: (context) => {
      context.commit("failedEntrance");
    },

    resetEntranceState: (context) => {
      context.commit("resetEntranceState");
    },

    setEntranceMessage: (context, message) => {
      context.commit("setEntranceMessage", message);
    },

    successfullVerification: (context) => {
      context.commit("successfullVerification");
    },

    failedVerification: (context) => {
      context.commit("failedVerification");
    },

    resetVerificationState: (context) => {
      context.commit("resetVerificationState");
    },

    setVerificationMessage: (context, message) => {
      context.commit("setVerificationMessage", message);
    },

    setIsLoading: async (context) => {
      context.commit("setIsLoading");
    },

    setIsNotLoading: (context) => {
      context.commit("setIsNotLoading");
    },

    shouldSlideChangePhoneIn: (context) => {
      context.commit("shouldSlideChangePhoneIn");
    },

    shouldNotSlideChangePhoneIn: (context) => {
      context.commit("shouldNotSlideChangePhoneIn");
    },

    shouldSlideChangePhoneOut: (context) => {
      context.commit("shouldSlideChangePhoneOut");
    },

    shouldNotSlideChangePhoneOut: (context) => {
      context.commit("shouldNotSlideChangePhoneOut");
    },

    successfullChangePhone: (context) => {
      context.commit("successfullChangePhone");
    },

    failedChangePhone: (context) => {
      context.commit("failedChangePhone");
    },

    resetChangePhoneState: (context) => {
      context.commit("resetChangePhoneState");
    },

    setChangePhoneMessage: (context, message) => {
      context.commit("setChangePhoneMessage", message);
    },

    successfullChangeVerification: (context) => {
      context.commit("successfullChangeVerification");
    },

    failedChangeVerification: (context) => {
      context.commit("failedChangeVerification");
    },

    resetChangeVerificationState: (context) => {
      context.commit("resetChangeVerificationState");
    },

    setChangeVerificationMessage: (context, message) => {
      context.commit("setChangeVerificationMessage", message);
    },

    successfullProfileChanges: (context) => {
      context.commit("successfullProfileChanges");
    },

    failedProfileChanges: (context) => {
      context.commit("failedProfileChanges");
    },

    resetProfileChangesState: (context) => {
      context.commit("resetProfileChangesState");
    },

    setProfileChangesMessage: (context, message) => {
      context.commit("setProfileChangesMessage", message);
    },

    successfullUserDeletion: (context) => {
      context.commit("successfullUserDeletion");
    },

    failedUserDeletion: (context) => {
      context.commit("failedUserDeletion");
    },

    goToDefaultUserDeleteState: (context) => {
      context.commit("goToDefaultUserDeleteState");
    },

    setDeleteUserMessage: (context, message) => {
      context.commit("setDeleteUserMessage", message);
    },

    setExtraLanguages: (context, extraLanguages) => {
      context.commit("setExtraLanguages", extraLanguages);
    },

    setSelectedExtraLanguage: (context, selectedExtraLanguage) => {
      context.commit("setSelectedExtraLanguage", selectedExtraLanguage);
    },

    setLanguage: (context, language) => {
      context.commit("setLanguage", language);
    },

    setDefaultLanguage: (context, language) => {
      context.commit("setDefaultLanguage", language);
    },

    setLanguageOnlyToMemory: (context, language) => {
      context.commit("setLanguageOnlyToMemory", language);
    },

    openLanguageModal: (context) => {
      context.commit("openLanguageModal");
    },

    closeLanguageModal: (context) => {
      context.commit("closeLanguageModal");
    },

    translateAllCategoriesOption: (context, translation) => {
      context.commit("translateAllCategoriesOption", translation);
    },

    setPaymentType: (context, type) => {
      context.commit("setPaymentType", type);
    },

    selectCard: (context, id) => {
      context.commit("selectCard", id);
    },

    setAvailableCards: (context, cards) => {
      context.commit("setAvailableCards", cards);
    },

    deleteCard: (context, card) => {
      context.commit("deleteCard", card);
    },

    openDeleteCreditCardModal: (context) => {
      context.commit("openDeleteCreditCardModal");
    },

    closeDeleteCreditCardModal: (context) => {
      context.commit("closeDeleteCreditCardModal");
    },

    setCreditCardToDelete: (context, card) => {
      context.commit("setCreditCardToDelete", card);
    },

    setCustomItems: (context, customItems) => {
      context.commit("setCustomItems", customItems);
    },

    setAvailableTables: (context, tables) => {
      context.commit("setAvailableTables", tables);
    },

    setSelectedTable: (context, table) => {
      context.commit("setSelectedTable", table);
    },

    setAvailableChargingRooms: (context, chargingRooms) => {
      context.commit("setAvailableChargingRooms", chargingRooms);
    },

    setSelectedChargingRoom: (context, chargingRoom) => {
      context.commit("setSelectedChargingRoom", chargingRoom);
    },

    setDeliveryInfo: (context, deliveryInfo) => {
      context.commit("setDeliveryInfo", deliveryInfo);
    },

    setDeliveryAddress: (context, address) => {
      context.commit("setDeliveryAddress", address);
    },

    setDeliveryArea: (context, area) => {
      context.commit("setDeliveryArea", area);
    },

    setDeliveryPostalCode: (context, postalCode) => {
      context.commit("setDeliveryPostalCode", postalCode);
    },

    setDeliveryFloor: (context, floor) => {
      context.commit("setDeliveryFloor", floor);
    },

    setDeliveryDoorbell: (context, doorbell) => {
      context.commit("setDeliveryDoorbell", doorbell);
    },

    deliveryInfoFormIsOpen: (context) => {
      context.commit("deliveryInfoFormIsOpen");
    },

    deliveryInfoFormIsNotOpen: (context) => {
      context.commit("deliveryInfoFormIsNotOpen");
    },

    disableDeliveryInfoForm: (context) => {
      context.commit("disableDeliveryInfoForm");
    },

    enableDeliveryInfoForm: (context) => {
      context.commit("enableDeliveryInfoForm");
    },

    doesHaveDeliveryDiscount: (context) => {
      context.commit("doesHaveDeliveryDiscount");
    },

    doesNotHaveDeliveryDiscount: (context) => {
      context.commit("doesNotHaveDeliveryDiscount");
    },

    setDeliveryDiscountPercentage: (context, discountPercentage) => {
      context.commit("setDeliveryDiscountPercentage", discountPercentage);
    },

    setMinimumPriceForDeliveryDiscount: (context, minimumPriceForDeliveryDiscount) => {
      context.commit("setMinimumPriceForDeliveryDiscount", minimumPriceForDeliveryDiscount);
    },


    setCurrentGreekDate: (context, currentGreekDate) => {
      context.commit("setCurrentGreekDate", currentGreekDate);
    },
  },
});
