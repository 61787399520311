<template>
  <button :style="cssVars" v-if="!$attrs.loading" @click="onClick" class="orderup-button center">
    <slot></slot>
  </button>
  <button
    :style="cssVars"
    v-else
    :disabled="$attrs.loading"
    @click="onClick"
    class="orderup-button center"
  >
    <beat-loader
      :loading="$attrs.loading"
      :color="isWhiteButton ? `#198b4a` : `white`"
      :size="size"
    ></beat-loader>
  </button>
</template>

<script>
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

export default {
  name: "Button",
  props: ["isWhiteButton"],
  components: {
    BeatLoader,
  },
  computed: {
    size() {
      return "8px";
    },

    cssVars() {
      if (this.hoverColor) {
        return {
          "--bg-color": this.hoverColor,
        };
      } else {
        if (this.isWhiteButton) {
          return {
            "--bg-color": "white",
          };
        } else {
          return {
            "--bg-color": "unset",
          };
        }
      }
    },
  },

  methods: {
    onClick: function () {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderup-button {
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 8px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  box-shadow: 3px 3px 10px -2px rgba(0, 0, 0, 0.2),
    2px 2px 2px 0 rgba(0, 0, 0, 0.14), 5px 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 0.5rem 1rem 0.5rem 1rem;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-out 0s;
}

.orderup-button::-moz-focus-inner {
  border: none;
}

/* Ripple */
.orderup-button::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  /* padding: 10% 45% 10% 45%; */
  padding: 50%;
  /* width: 100%; Safari */
  /* height: 100%; Safari */
  /* background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255)); */
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.orderup-button:hover,
.orderup-button:focus {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.orderup-button:hover::before {
  opacity: 0.08;
}

.orderup-button:focus::before {
  opacity: 0.24;
}

.orderup-button:hover:focus::before {
  opacity: 0.3;
}

/* Active */
.orderup-button:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
  background-size: 100%;
  transition: background 0s;
}

.orderup-button:active::after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

/* Disabled */
.orderup-button:disabled {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.12);
  box-shadow: none;
  cursor: initial;
}

.orderup-button:disabled::before {
  opacity: 0;
}

.orderup-button:disabled::after {
  opacity: 0;
}

// .orderup-button:hover {
//   background: var(--bg-color) !important;
// }
</style>